import * as XLSX from 'xlsx';
import { FileState } from '../../components/DragAndDropZone/DragAndDropZone';
import { ApiConstants } from '../constants/api.constants';
import { Constants } from '../constants/constants';

export const buildEncodeFileDetailUrl = (taskQueueId: string, isError: boolean): string => {
  let url = `${ApiConstants.OnixMakerApi.PreviewFile.PreviewFileUrl()}?id=${taskQueueId}`;

  if (isError) {
    let paramsEncode = encodeURIComponent(`&isError=true`);
    url = url + paramsEncode;
  }

  return url;
};

export const getNameFromPath = (filePath: string): string => {
  if(filePath){
    var fileName = filePath.substring(filePath.lastIndexOf('/') + 1) ?? '';
    return fileName;
  }
  return filePath;
};

export const removeExtensionFileName = (fullName: string): string => {
  var fileName = fullName.split('.').slice(0, -1).join('.') ?? '';
  return fileName;
};

export const base64ToBlob = (base64String: string, contentType = '') => {
  const byteCharacters = atob(base64String);
  const byteArrays = [];

  for (let i = 0; i < byteCharacters.length; i++) {
    byteArrays.push(byteCharacters.charCodeAt(i));
  }

  const byteArray = new Uint8Array(byteArrays);
  return new Blob([byteArray], { type: contentType });
};

export const downloadFileFromBlob = (blobUrl: string, fileName: string, parent?: HTMLElement) => {
  //download file action
  const link = document.createElement('a');
  link.href = blobUrl;
  link.download = fileName;
  link.setAttribute('download', fileName);
  if (parent === undefined) {
    document.body.appendChild(link);
  } else {
    parent.appendChild(link);
  }
  link.click();
  link.remove();
};

export const removeHyperLink = (uploadingFile: File): Promise<File> => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = (event) => {
      const data = new Uint8Array(event.target?.result as ArrayBuffer);
      try {
        const workbook = XLSX.read(data, { type: 'array' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];

        // Iterate through all cells
        for (const cellAddress in worksheet) {
          if (worksheet.hasOwnProperty(cellAddress)) {
            const cell = worksheet[cellAddress];
            if (cell && cell.l && cell.l.Target) {
              delete cell.l; // Remove hyperlink
            }
          }
        }
        // Write the updated workbook content to a new file
        const updatedData = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' });
        const updatedFile = new File([updatedData], uploadingFile.name, { type: uploadingFile.type });

        resolve(updatedFile);
      } catch (e) {
        reject(e); // Reject with error
      }
    };
    reader.onerror = () => {
      reject('Oops, something went wrong with the file reader.');
    };
    reader.readAsArrayBuffer(uploadingFile);
  });
};

export const readFileContent = (uploadingFile: FileState, rowReading?: number): Promise<[any[], number, number]> => {
  const file = uploadingFile.file;
  const reader = new FileReader();
  let rowCnt = rowReading ?? 0;

  return new Promise((resolve, reject) => {
    reader.onload = (event) => {
      const data = new Uint8Array(event.target?.result as ArrayBuffer);
      try {
        const workbook = XLSX.read(data, { type: 'array', sheetRows: rowCnt });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        /* ws is the worksheet, e.g. workbook.Sheets["Sheet1"] */
        var range = XLSX.utils.decode_range(sheet['!ref'] ?? '');
        range.s.r = range.s.c = 0; // force range to start at A1
        sheet['!ref'] = XLSX.utils.encode_range(range);
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1, blankrows: true, defval: '' }) as any[];

        const totalEquipment = getTotalEquipment(jsonData);
        const totalSheet = workbook.SheetNames.length;

        resolve([jsonData, totalEquipment, totalSheet]);
      } catch (e) {
        throw e;
      }
    };
    reader.onerror = () => {
      reject('oops, something went wrong with the file reader.');
    };
    reader.readAsArrayBuffer(file);
  });
};

export const getTotalEquipment = (fileData: any[]) => {
  let totalEquipment = 0;
  if (fileData.length > 0) {
    const header = fileData[0] as string[];
    const indexProductNo = header.findIndex((m) => m.toString().trim() === Constants.ProductNoColumn);
    const indexSerialNo = header.findIndex((m) => m.toString().trim() === Constants.SerialNoColumn);
    const indexBatchNo = header.findIndex((m) => m.toString().trim() === Constants.BatchNoColumn);
    const indexOwnerEquipmentId = header.findIndex((m) => m.toString().trim() === Constants.OwnerEquipmentIdColumn);
    const indexProdYear = header.findIndex((m) => m.toString().trim() === Constants.ProdYearColumn);
    const indexGlobalId = header.findIndex((m) => m.toString().trim() === Constants.GlobalIdColumn);

    for (let index = 1; index < fileData.length; index++) {
      const hasProductNoValue = indexProductNo >= 0 && fileData[index][indexProductNo] !== '';
      const hasSerialNoValue = indexSerialNo >= 0 && fileData[index][indexSerialNo] !== '';
      const hasBatchNoValue = indexBatchNo >= 0 && fileData[index][indexBatchNo] !== '';
      const hasOwnerEquipmentIdValue = indexOwnerEquipmentId >= 0 && fileData[index][indexOwnerEquipmentId] !== '';
      const hasProdYearValue = indexProdYear >= 0 && fileData[index][indexProdYear] !== '';
      const hasGlobalIdValue = indexGlobalId >= 0 && fileData[index][indexGlobalId] !== '';

      if (hasProductNoValue || hasSerialNoValue || hasBatchNoValue || hasOwnerEquipmentIdValue || hasProdYearValue || hasGlobalIdValue) {
        totalEquipment++;
      }
    }
  }
  return totalEquipment;
};
