import { Outlet } from 'react-router-dom';
import './index.scss';

export default function LoginLayout() {
  return (
    <div className="login-layout">
      <Outlet></Outlet>
    </div>
  );
}
