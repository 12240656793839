import { Icon, Link } from '@fluentui/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TrackingEvent } from '../../../core/constants/tracking-event.constant';
import { useTracking } from '../../../hooks/useTracking';
import { ValidatedError } from '../../../models/validated-error.model';
import './index.scss';

interface ValidatedErrorModalProps {
  validatedErrors: ValidatedError[];
  fileName: string;
  fileContent: any[];
  onClickDownloadErrorLog: (errors: ValidatedError[], fileName: string, fileContent: any[]) => void;
}

export default function ValidatedErrorModal(props: ValidatedErrorModalProps) {
  const [translate] = useTranslation();
  const { trackEvent } = useTracking();

  useEffect(() => {
    trackEvent(TrackingEvent.ValidateFileUpload);
  }, [trackEvent]);

  const onClickDownloadErrorLog = () => {
    props.onClickDownloadErrorLog(props.validatedErrors, props.fileName, props.fileContent);
  };

  const invalidDataFileDescription = translate('CaptionResource.InvalidDataFileDescription').replace(/<br>/g, '<br/>');

  return (
    <>
      <div className="error-content">
        <Icon iconName="Error" className="error-icon"></Icon>
        <p className="action-required-text">{translate('CaptionResource.ActionRequiredCorrectFileErrors')}</p>
        <p className="invalid-description" dangerouslySetInnerHTML={{ __html: invalidDataFileDescription }}></p>
        <p>
          <Link className="download-error-link" onClick={onClickDownloadErrorLog}>
            {translate('CaptionResource.DownloadErrorDetails')}
          </Link>
        </p>
      </div>
    </>
  );
}
