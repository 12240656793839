import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { hasToken } from '../services/authentication.service';
import { userSelector } from '../states/user/userSlice';

export const useTracking = () => {
  const appInsights = useAppInsightsContext();
  const isLoggedIn = hasToken();
  const user = useSelector(userSelector);

  const defaultProperties = useMemo(() => {
    return {
      companyId: isLoggedIn ? user.companyId : 0,
      companyName: isLoggedIn ? user.companyName : '',
      name: isLoggedIn ? `${user.fullName}` : '',
      userAccountId: isLoggedIn ? user.userAccountId : null,
      contactId: isLoggedIn ? user.contactId : null,
      userEmail: isLoggedIn ? user.email : '',
    };
  }, [user, isLoggedIn]);

  const trackPageView = useCallback(
    (pageName: string, customProperties?: any) => {
      const properties = customProperties ? Object.assign(customProperties, defaultProperties) : defaultProperties;
      appInsights.trackPageView({ name: pageName, properties });
    },
    [appInsights, defaultProperties]
  );

  const trackError = useCallback(
    (name: string, exception: any, customProperties?: any) => {
      const properties = customProperties ? Object.assign(customProperties, defaultProperties) : defaultProperties;
      appInsights.trackException({ id: name, exception: exception }, properties);
    },
    [appInsights, defaultProperties]
  );

  const trackEvent = useCallback(
    (name: string, customProperties?: any, parentPageName?: string) => {
      const properties = customProperties ? Object.assign(customProperties, defaultProperties) : defaultProperties;
      if (parentPageName) {
        properties.parentPage = parentPageName;
      }

      appInsights.trackEvent({ name, properties });
    },
    [appInsights, defaultProperties]
  );

  return { trackPageView, trackEvent, trackError };
};
