import ApplicationSetting from './application.constants';

const onixMakerApiUrl = ApplicationSetting.apiUrl;
const identityApiUrl = ApplicationSetting.identityApiUrl;

export const ApiConstants = {
  OnixMakerApi: {
    Setting: {
      GetLanguages: `${onixMakerApiUrl}/settings/languages`,
    },
    Account: {
      UpdatePreferredLanguage: () => onixMakerApiUrl + `/accounts/preferred-languages`,

      GetSuggestion: () => onixMakerApiUrl + `/accounts/get-suggestion`,
      GetSuggestionOnixExpress: () => onixMakerApiUrl + `/accounts/get-suggestion-onix-express`,
      CreateAccount: () => onixMakerApiUrl + `/accounts/create`,
      CreateAccountOnixExpress: () => onixMakerApiUrl + `/accounts/create-onix-express`,
      GetInfoToResetPassword: () => onixMakerApiUrl + `/accounts/reset-passwords`,
      GetInfoToResetPasswordOnixExpress: () => onixMakerApiUrl + `/accounts/reset-passwords-onix-express`,
      ResetPassword: () => onixMakerApiUrl + `/accounts/reset-passwords`,
      ResetPasswordOnixExpress: () => onixMakerApiUrl + `/accounts/reset-passwords-onix-express`,
      GetInformation: () => onixMakerApiUrl + `/accounts`,
      UpdateAvatarPicture: () => onixMakerApiUrl + `/accounts/pictures`,
      UpdateSignature: () => onixMakerApiUrl + `/accounts/signatures`,
      UpdateInformation: () => onixMakerApiUrl + `/accounts`,
      UpdateMainCompany: (contactId: number) => onixMakerApiUrl + `/accounts/main-companies?contactId=${contactId}`,
      GetMemberCompanyInvitation: () => onixMakerApiUrl + `/accounts/member-invitations`,
      GetExpressCompanyInvitation: () => onixMakerApiUrl + `/accounts/express-invitations`,
      CreateMemberUserByInvitation: (invitationId: number) => onixMakerApiUrl + `/accounts/member-invitations/${invitationId}/users`,
      CreateExpressUserByInvitation: (invitationId: number) => onixMakerApiUrl + `/accounts/express-invitations/${invitationId}/users`,
    },
    Organization: {
      GetMember: () => onixMakerApiUrl + `/organizations/members`,
    },
    UploadHistory: {
      GetUploadHistory: () => onixMakerApiUrl + `/task-queues`,
      DownloadFileDetail: () => onixMakerApiUrl + `/task-queues/{id}/files`,
    },
    HandleExcel: {
      DownloadTemplateExcelFile: () => onixMakerApiUrl + `/downloads/download-template`,
      UploadExcelFile: () => onixMakerApiUrl + `/task-queues/equipments`,
    },
    PreviewFile: {
      PreviewFileUrl: () => onixMakerApiUrl + `/preview-files`,
    },
  },
  Identity: {
    Account: {
      // GetContact: () => identityApiUrl + `/accounts/contact`
      SendResetPasswordEmail: () => identityApiUrl + `/accounts/send-reset-password-email`,
    },
  },
};
