import { DefaultButton, IDialogContentProps, PrimaryButton, Spinner, SpinnerSize } from '@fluentui/react';
import { Dialog, Modal } from '@onix/common/src/components';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DialogTypeEnum } from './core/constants/enums/dialog-type.enum';
import { hiddenCommonDialog, hiddenConfirmDialog, hiddenErrorDialog } from './services/dialog.service';
import { appIsLoadingSelector, commonSelector, confirmDialogSelector, errorSelector } from './states/app/appSlice';
import { ModalActions, modalSelector } from './states/modal/modalSlice';
import { appDispatch } from './states/store';

export function CommonGlobal() {
  const [translate] = useTranslation();
  const dispatch = appDispatch;

  const isLoading = useSelector(appIsLoadingSelector);
  const errorDialog = useSelector(errorSelector);
  const confirmDialog = useSelector(confirmDialogSelector);
  const commonDialog = useSelector(commonSelector);

  const modal = useSelector(modalSelector);

  const errorDialogContentProps = useMemo(() => {
    let title = errorDialog.title;
    if (errorDialog.type === DialogTypeEnum.Error) {
      title = translate('CaptionResource.CAPTION_ERROR');
    } else if (errorDialog.type === DialogTypeEnum.Warning) {
      title = translate('CaptionResource.CAPTION_WARNING');
    }
    return {
      title: title,
      subText: errorDialog.message !== '' ? errorDialog.message : translate(errorDialog.messageCaption),
    } as IDialogContentProps;
  }, [errorDialog, translate]);

  const commonDialogContentProps = useMemo(() => {
    return {
      title: commonDialog.title,
      subText: commonDialog.message,
      showCloseButton: true,
    } as IDialogContentProps;
  }, [commonDialog, translate]);

  const onDismissCommonDialog = useCallback(() => {
    hiddenCommonDialog();
  }, []);

  const onDismissErrorDialog = useCallback(() => {
    hiddenErrorDialog();
  }, []);

  const onDismissConfirmDialog = useCallback(async () => {
    if (confirmDialog.onCancel) {
      await confirmDialog.onCancel();
    }
    hiddenConfirmDialog();
  }, [confirmDialog]);

  const onAcceptConfirmDialog = useCallback(async () => {
    if (confirmDialog.onAccept) {
      await confirmDialog.onAccept();
    }
    hiddenConfirmDialog();
  }, [confirmDialog]);

  const onModalDismiss = () => {
    if (!modal.hasConfirm) {
      modal.cancelEvent();
      dispatch(ModalActions.hiddenModal());
    } else {
      modal.confirmEvent();
    }
  };

  return (
    <>
      {isLoading && (
        <div className="spinner-overlay">
          <Spinner size={SpinnerSize.large}></Spinner>
        </div>
      )}

      <Dialog
        isHidden={commonDialog.isHidden}
        dialogContentProps={commonDialogContentProps}
        onDismiss={onDismissCommonDialog}
        modalProps={{ isBlocking: true }}
        maxWidth={560}
        footerChildren={<PrimaryButton onClick={onDismissCommonDialog} text={translate('CommonResource.cmdOk')} />}
      />

      <Dialog
        isHidden={errorDialog.isHidden}
        dialogContentProps={errorDialogContentProps}
        onDismiss={onDismissErrorDialog}
        footerChildren={<PrimaryButton onClick={onDismissErrorDialog}>{translate('CaptionResource.CAPTION_OK')}</PrimaryButton>}
      />

      <Dialog
        isHidden={confirmDialog.isHidden}
        dialogContentProps={confirmDialog.dialogContentProps}
        modalProps={confirmDialog.modalProps}
        onDismiss={onDismissConfirmDialog}
        footerChildren={
          <div className="confirmation-dialog-footer">
            <PrimaryButton onClick={onAcceptConfirmDialog}>
              {confirmDialog.confirmBtnName !== '' ? confirmDialog.confirmBtnName : translate('CaptionResource.Yes')}
            </PrimaryButton>
            <DefaultButton onClick={onDismissConfirmDialog}>
              {confirmDialog.cancelBtnName !== '' ? confirmDialog.cancelBtnName : translate('CaptionResource.No')}
            </DefaultButton>
          </div>
        }
      >
        {confirmDialog.content}
      </Dialog>

      <Modal
        props={modal.props}
        isModalOpen={modal.isOpen}
        onDismiss={onModalDismiss}
        title={modal.title}
        description={modal.description}
        children={modal.component}
        style={modal.style}
        closeIcon={modal.closeIcon}
      />
    </>
  );
}
