import { ICalloutProps, ITooltipHostProps, IconButton, TextField, TooltipHost, styled } from '@fluentui/react';
import { CSSProperties, ForwardedRef, KeyboardEventHandler, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledCustomSearchBox } from '../../assets/layouts';

export interface ISearchBoxRef {
  clear: () => void;
}

export type ISearchBoxProps = {
  placeholder?: string;
  classNames?: string;
  calloutProps?: ICalloutProps;
  disabled?: boolean;
  style?: CSSProperties;
  onChange?: (event?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => void;
  onSearch?: (newValue?: string) => void;
  onClear?: () => void;
  onEscape?: () => void;
  searchTextValue?: string;
  inputWidth?: string;
  searchBtnTooltipHostProps?: ITooltipHostProps;
};

export const CustomSearchBox = forwardRef((props: ISearchBoxProps, ref: ForwardedRef<ISearchBoxRef>) => {
  const [translate] = useTranslation();
  const [searchText, setSearchText] = useState<string | undefined>('');
  const defaultInputWidth = '300px';
  const width = props.inputWidth || defaultInputWidth;

  useImperativeHandle(ref, () => ({
    clear() {
      setSearchText('');
    },
  }));

  useEffect(() => {
    if (props.searchTextValue) {
      setSearchText(props.searchTextValue);
    }
  }, [props.searchTextValue]);

  const onRenderSuffixBtn = () => {
    return (
      <div>
        {searchText !== '' && !props.disabled && (
          <IconButton className="btn-clear-text" iconProps={{ iconName: 'Cancel' }} style={{}} onClick={onClear} />
        )}
        <TooltipHost {...props.searchBtnTooltipHostProps}>
          <IconButton className="btn-search" iconProps={{ iconName: 'Search' }} onClick={onSearch} disabled={props.disabled} />
        </TooltipHost>
      </div>
    );
  };

  const onChange = (event?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    setSearchText(newValue);
    if (props.onChange) {
      props.onChange(undefined, newValue);
    }
  };

  const onClear = () => {
    setSearchText('');
    if (props.onClear) {
      props.onClear();
    }
  };

  const onSearch = () => {
    if (props.onSearch) {
      props.onSearch(searchText);
    }
  };

  const onKeyDownEvent: KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement> = (ev) => {
    switch (ev.key) {
      case 'Enter':
        onSearch();
        break;
      case 'Escape':
        onClear();
        break;
    }
  };

  return (
    <StyledCustomSearchBox
      value={searchText}
      placeholder={props?.placeholder ? translate(props.placeholder) : undefined}
      className={props.classNames}
      disabled={props.disabled}
      style={{
        width,
        ...props.style,
      }}
      styles={{ suffix: { backgroundColor: 'red' } }}
      onRenderSuffix={onRenderSuffixBtn}
      onChange={onChange}
      onKeyDown={onKeyDownEvent}
    />
  );
});