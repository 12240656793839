import { useTranslation } from 'react-i18next';
import './index.scss';

export default function InstructionModal() {
  const [translate] = useTranslation();
  return (
    <>
      <div>
        <ul className="list-content">
          <li>{translate('CaptionResource.requiredMustBeExcelFile')}.</li>
          <li>{translate('CaptionResource.requiredAndMatchProductNo')}.</li>
          <li>{translate('CaptionResource.equipmentInformationMustFollowRules')}.</li>
          <li>{translate('CaptionResource.requiredSerialNoAndBatchNo')}.</li>
          <li>{translate('CaptionResource.mandatoryUniqueGlobalId')}.</li>
          <li>{translate('CaptionResource.maxLengthSerialNo')}.</li>
          <li>{translate('CaptionResource.maxLengthBatchNo')}.</li>
          <li>{translate('CaptionResource.maxLengthOwnerEquipmentId')}.</li>
          <li>{translate('CaptionResource.maxLengthProdYear')}.</li>
          <li>{translate('CaptionResource.requiredContentDataExcel')}.</li>
        </ul>
      </div>
    </>
  );
}
