import { IPersonaSharedProps, Persona } from "@fluentui/react";
import { MutableRefObject, useCallback, useMemo } from "react";
import "./index.scss";

type Props = {
    ref?: MutableRefObject<any>;
    avatarClicked?: any;
    props?: IPersonaSharedProps;
}

export default function Avatar(props: Props) {
    const {avatarClicked, props: personaPropsSetting} = props;

    const avatarClick = useCallback(() => {
        if (avatarClicked) {
            avatarClicked();
        }
    }, [avatarClicked])

    const personaProps = useMemo(() => {
        return {
            ...personaPropsSetting,
            initialsColor: personaPropsSetting?.initialsColor ? personaPropsSetting?.initialsColor : "#069a8e"
        }
    }, [personaPropsSetting])

    return (
        <div ref={props?.ref} onClick={avatarClick} className="avatar-wrapper">
            <Persona {...personaProps}>
            </Persona>
        </div>
    )
}