import { generateLocalKeyByName } from "../../services/common.service";

const setLocalStorageItem = (key: string, value: string | null | undefined) =>
  localStorage.setItem(generateLocalKeyByName(key), value ?? '');

const getLocalStorageItem = (key: string) => localStorage.getItem(generateLocalKeyByName(key));

const removeLocalStorageItem = (key: string) => localStorage.removeItem(generateLocalKeyByName(key));

export {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
};
