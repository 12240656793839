import { FileType } from '../enums/file-type.enum';

const KB = 1024;
const MB = KB * 1024;
const IMAGE_EXTS = ['jpg', 'jpeg', 'gif', 'png', 'bmp'];
const WORD_EXTS = ['docx', 'doc'];
const EXCEL_EXTS = ['xlsx', 'xls'];
const PDF_EXTS = ['pdf'];

export const FileConstants = {
  Size: {
    KB: {
      size: KB,
      name: 'KB',
    },
    MB: {
      size: MB,
      name: 'MB',
    },
  },

  Icon: {
    [FileType.Image]: 'FileImage',
    [FileType.Pdf]: 'PDF',
    [FileType.Excel]: 'ExcelDocument',
    [FileType.Word]: 'WordDocument',
    [FileType.Unsupported]: 'PageRemove',
  },

  Exts: {
    image: {
      exts: IMAGE_EXTS,
      type: FileType.Image,
    },
    word: {
      exts: WORD_EXTS,
      type: FileType.Word,
    },
    excel: {
      exts: EXCEL_EXTS,
      type: FileType.Excel,
    },
    pdf: {
      exts: PDF_EXTS,
      type: FileType.Pdf,
    },
  },

  AllSupportedExtensions: [IMAGE_EXTS, WORD_EXTS, EXCEL_EXTS, PDF_EXTS].flat(),
  ExcelSupportedExtensions: [EXCEL_EXTS].flat(),
  ImageSupportedExtensions: [IMAGE_EXTS].flat(),
  DocumentUpload: {
    MaxSize: 30 * MB,
    MaxSizeFormatted: '30 MB',
    ReadableCheckIntervalInMs: 2000,
    MaxDescriptionLength: 100,
    MaxUploadParallel: 1, // Let upload one by one for now.
  },

  ImageUpload: {
    MaxSize: 4 * MB,
    MaxSizeFormatted: '4 MB',
    ReadableCheckIntervalInMs: 2000,
    MaxDescriptionLength: 100,
    MaxUploadParallel: 1, // Let upload one by one for now.
  },

  MICROSOFT_DOCUMENT_VIEWER_URL: 'https://view.officeapps.live.com/op/view.aspx?src=',
  Base64: 'data:image/jpeg;base64',
  OLD_EXCEL_EXTS: 'xls',
};
