import { Link } from '@fluentui/react';
import axios, { CancelTokenSource } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as XLSX from 'xlsx';
import { FileConstants } from '../../common/constants/file.constant';
import { UploadFileConstant } from '../../common/constants/upload-file.constant';
import { FileType } from '../../common/enums/file-type.enum';
import { DragAndDropZone, FileState, FileStateData, UploadState } from '../../components/DragAndDropZone/DragAndDropZone';
import { Constants } from '../../core/constants/constants';
import { TrackingEvent } from '../../core/constants/tracking-event.constant';
import { downloadFileFromBlob, readFileContent, removeExtensionFileName } from '../../core/utils/fileHelper';
import { useTracking } from '../../hooks/useTracking';
import { ValidatedError } from '../../models/validated-error.model';
import { showCommonDialog, showConfirmDialog } from '../../services/dialog.service';
import { downloadTemplateExcelFile } from '../../services/download-excel.service';
import { hiddenModal, showModal } from '../../services/modal.service';
import { AppActions } from '../../states/app/appSlice';
import { ShowModalPayload } from '../../states/modal/modalSlice';
import { appDispatch } from '../../states/store';
import InstructionModal from './InstructionModal/InstructionModal';
import UnExpectedErrorModal from './UnExpectedErrorModal/UploadFileSuccessModal';
import UploadFileModal from './UploadFileModal/UploadFileModal';
import UploadFileSuccessModal from './UploadFileSuccessModal/UploadFileSuccessModal';
import ValidatedErrorModal from './ValidatedErrorModal/ValidatedErrorModal';
import './index.scss';

export const Home = () => {
  const [translate] = useTranslation();
  const { trackPageView, trackError } = useTracking();
  const [cancelTokenSource, setCancelTokenSource] = useState<CancelTokenSource>();

  //#region File upload
  const acceptExts = FileConstants.ExcelSupportedExtensions.map((e) => `.${e}`).join(',');

  const defaultSelectedDocTypeKey = '5'; // Others.

  useEffect(() => {
    trackPageView(TrackingEvent.HomePage);
  }, []);

  const onChangeFileStateList = useCallback(
    async (fsList: FileState<FileStateData>[]) => {
      appDispatch(AppActions.showLoading());
      try {
        if (!fsList.length) {
          showValidatedDialog(translate('CommonResource.ValidateUploadedFile.FileFormatError'));
          return;
        }

        if (fsList.length > 1) {
          showValidatedDialog(translate('CommonResource.ValidateUploadedFile.MultipleFileError'));
          return;
        }

        const selectedFile = fsList[0];
        if (
          selectedFile.type !== FileType.Excel ||
          (selectedFile.type === FileType.Excel && selectedFile.description.split('.').pop() === FileConstants.OLD_EXCEL_EXTS)
        ) {
          showValidatedDialog(translate('CommonResource.ValidateUploadedFile.FileFormatError'));
          return;
        }

        if (selectedFile.file.size > FileConstants.DocumentUpload.MaxSize) {
          showValidatedDialog(translate('CommonResource.ValidateUploadedFile.FileSizeError'));
          return;
        }

        const [fileData, totalEquipment, totalSheet] = await readFileContent(selectedFile);
        const validateContent = () => {
          const { isValid, message } = validateContentFile(fileData, totalEquipment);
          if (!isValid) {
            showValidatedDialog(message);
            return;
          }

          const defaultFileStateData: FileStateData = {
            type: defaultSelectedDocTypeKey,
            isValid: false,
            isExist: true,
            uploadState: UploadState.New,
          };

          fsList.forEach((fs) => {
            fs.data = defaultFileStateData;
          });

          const cancelTokenSource = axios.CancelToken.source();
          setCancelTokenSource(cancelTokenSource);

          const onDismissConfirm = () => {
            showConfirmDialog({
              dialogContentProps: {
                title: <>{translate('CommonResource.ConfirmExit')}</>,
                showCloseButton: true,
                subText: translate('CommonResource.UploadingFile.ConfirmLeave'),
              },
              confirmBtnName: translate('CaptionResource.Leave'),
              cancelBtnName: translate('CaptionResource.Caption53'),

              onAccept: () => {
                hiddenModal();
                cancelTokenSource?.cancel(Constants.cancelUploadFile);
              },
            });
          };

          const showModalPayload: ShowModalPayload = {
            component: (
              <UploadFileModal
                file={selectedFile}
                header={fileData[0]}
                onCompleteUpload={onCompleteUpload}
                onUnexpectedError={onUnexpectedError}
                onValidatingError={onValidatingError}
                cancelTokenSource={cancelTokenSource}
              />
            ),
            props: { isBlocking: true, className: 'validating-uploading-modal' },
            style: { display: 'flex', flexFlow: 'column' },
            confirmEvent: onDismissConfirm,
            title: translate('CaptionResource.titleValidatingAndUploading'),
            description: <></>,
            closeIcon: true,
            hasConfirm: true,
          };

          appDispatch(AppActions.hiddenLoading());

          showModal(showModalPayload);
        };

        // Handle multiple sheets in Excel file.
        if (totalSheet > 1) {
          appDispatch(AppActions.hiddenLoading());

          showConfirmDialog({
            dialogContentProps: {
              title: <>{translate('CaptionResource.titleMultipleSheets')}</>,
              showCloseButton: true,
              subText: translate('CaptionResource.uploadExcelMultipleSheets'),
            },
            confirmBtnName: translate('CaptionResource.CAPTION_OK'),
            cancelBtnName: translate('CaptionResource.Caption53'),

            onAccept: () => {
              appDispatch(AppActions.showLoading());
              validateContent();
            },
          });
        } else {
          validateContent();
        }
      } catch (error: any) {
        appDispatch(AppActions.hiddenLoading());
        trackError(TrackingEvent.UnExpectedError, error);
        onUnexpectedError({
          ...error,
          messageTitle: error?.messageTitle || error?.message || '',
          stackTrace: error?.stackTrace || error?.stack || '',
        });
      }
    },
    [translate]
  );

  const showInstructionModal = () => {
    const showModalPayload: ShowModalPayload = {
      component: <InstructionModal />,
      props: { isBlocking: true, className: 'instruction-modal' },
      style: { display: 'flex', flexFlow: 'column' },
      confirmEvent: () => {},
      title: translate('CaptionResource.titleInstructionsImportFile'),
      description: <></>,
      closeIcon: true,
    };

    showModal(showModalPayload);
  };

  const showValidatedErrorModal = (errors: ValidatedError[], fileName: string, fileContent: any[]) => {
    const showModalPayload: ShowModalPayload = {
      component: (
        <ValidatedErrorModal
          validatedErrors={errors}
          fileName={fileName}
          fileContent={fileContent}
          onClickDownloadErrorLog={onClickDownloadErrorLog}
        />
      ),
      props: { isBlocking: true, className: 'validated-error-modal' },
      style: { display: 'flex', flexFlow: 'column' },
      confirmEvent: () => {},
      title: <>{translate('CaptionResource.titleCannotUpload')}</>,
      description: <></>,
      closeIcon: true,
    };

    showModal(showModalPayload);
  };

  const onClickDownloadErrorLog = (errors: ValidatedError[], fileName: string, fileContent: any[]) => {
    try {
      const fileData = createExcelError(errors, fileContent);

      const workSheet = XLSX.utils.aoa_to_sheet(fileData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet);

      /* write data -- `writeFile` infers bookType from filename but `write` cannot */
      const u8 = XLSX.write(workBook, { bookType: 'xlsx', type: 'buffer' });
      /* create Blob */
      const blob = new Blob([u8]);
      /* create object URL */
      const url = URL.createObjectURL(blob);

      const modal = document.getElementsByClassName('error-content');
      if (modal.length > 0) {
        let errorName = `${removeExtensionFileName(fileName)}_error.xlsx`;
        downloadFileFromBlob(url, errorName, modal[0] as HTMLElement);
      }
    } catch (error: any) {
      trackError(TrackingEvent.UnExpectedError, error);
      onUnexpectedError({
        ...error,
        messageTitle: error?.messageTitle || error?.message || '',
        stackTrace: error?.stackTrace || error?.stack || '',
      });
    }
  };

  const createExcelError = (errors: ValidatedError[], fileContent: any[]): Array<string[]> => {
    const excel = structuredClone(fileContent) as Array<string[]>;

    const header = excel[0] as string[];
    const columnHeader = header.map((m, index) => {
      return {
        index,
        columnName: m.toString().trim(),
      };
    });

    const availableColumn = columnHeader.filter((m) => m.columnName !== '');
    const indexAvailableColumn = availableColumn.map((m) => m.index);
    const lastIdxHeader = Math.max(...indexAvailableColumn);

    //header
    const statusHeader = translate('CaptionResource.StatusImportExcel');
    const errorColumnIndex = lastIdxHeader + 1;
    excel[0].splice(errorColumnIndex, 0, statusHeader);

    const reasonHeader = translate('CaptionResource.ReasonImportExcel');
    const reasonColumnIndex = lastIdxHeader + 2;
    excel[0].splice(reasonColumnIndex, 0, reasonHeader);

    //data
    const errorMessage = translate('CaptionResource.ErrorImportExcel');
    const okMessage = translate('CaptionResource.OkImportExcel');

    excel.forEach((content, index) => {
      if (index > 0) {
        const row = errors.find((x) => x.rowNo === index);
        if (row) {
          if (row.isValid) {
            content[errorColumnIndex] = okMessage;
            content[reasonColumnIndex] = '';
          } else {
            const reasons = row.reasons.join('   \r\n');
            content[errorColumnIndex] = errorMessage;
            content[reasonColumnIndex] = reasons;
          }
        }
      }
    });

    return excel;
  };

  const downloadTemplateExcel = async () => {
    try {
      await downloadTemplateExcelFile('OnixMaker_Template.xlsx');
    } catch (error) {
      console.error('Error downloading Excel file:', error);
    }
  };

  const showValidatedDialog = (errorMsg: string) => {
    showCommonDialog(translate('CommonResource.ValidateUploadedFile.Title'), errorMsg);
    appDispatch(AppActions.hiddenLoading());
  };

  const validateContentFile = (content: any[], amountOfEquip: number): { isValid: boolean; message: string } => {
    const constraintHeader = UploadFileConstant.RequireHeader;
    const equipmentRequireHeader = UploadFileConstant.EquipmentRequireHeader;
    const result = {
      isValid: true,
      message: '',
    };

    if (content.length === 0) {
      result.isValid = false;
      result.message = translate('CommonResource.ValidateUploadedFile.MissingColumnError').replace(
        '{0}',
        UploadFileConstant.RequireHeaderMessage
      );
      return result;
    }

    const header = content[0] as Array<string>;
    const invalidEquipmentRequireHeader = new Array<string>();
    let missingHeader = '';

    const productNo = header.findIndex((m) => m.toString().trim() === constraintHeader.ProductNo);
    if (productNo === -1) {
      missingHeader += '"' + constraintHeader.ProductNo + '", ';
    }

    equipmentRequireHeader.forEach((x) => {
      const idx = header.findIndex((m) => m.toString().trim() === x);
      if (idx === -1) {
        invalidEquipmentRequireHeader.push(x);
      }
    });

    if (invalidEquipmentRequireHeader.length === 2) {
      missingHeader += `"${invalidEquipmentRequireHeader[0]}" or "${invalidEquipmentRequireHeader[1]}", `;
    }

    const globalId = header.findIndex((m) => m.toString().trim() === constraintHeader.GlobalId);
    if (globalId === -1) {
      missingHeader += '"' + constraintHeader.GlobalId + '", ';
    }

    if (missingHeader !== '') {
      missingHeader = missingHeader.trim().slice(0, -1);
      result.isValid = false;
      result.message = translate('CommonResource.ValidateUploadedFile.MissingColumnError').replace('{0}', missingHeader);
      return result;
    }

    if (amountOfEquip <= 0) {
      result.isValid = false;
      result.message = translate('CaptionResource.UploadEmptyFile');
      return result;
    }

    if (amountOfEquip > UploadFileConstant.AmountSupportEquipment) {
      result.isValid = false;
      result.message = translate('CommonResource.ValidateUploadedFile.OverLimitation').replace('{0}', amountOfEquip);
      return result;
    }

    const hasDuplicatedProductNo = header.filter((m) => m.toString().trim() === Constants.ProductNoColumn).length > 1;
    const hasDuplicatedSerialNo = header.filter((m) => m.toString().trim() === Constants.SerialNoColumn).length > 1;
    const hasDuplicatedBatchNo = header.filter((m) => m.toString().trim() === Constants.BatchNoColumn).length > 1;
    const hasDuplicatedOwnerEquipmentId = header.filter((m) => m.toString().trim() === Constants.OwnerEquipmentIdColumn).length > 1;
    const hasDuplicatedProdYear = header.filter((m) => m.toString().trim() === Constants.ProdYearColumn).length > 1;
    const hasDuplicatedGlobalId = header.filter((m) => m.toString().trim() === Constants.GlobalIdColumn).length > 1;

    if (
      hasDuplicatedProductNo ||
      hasDuplicatedSerialNo ||
      hasDuplicatedBatchNo ||
      hasDuplicatedOwnerEquipmentId ||
      hasDuplicatedProdYear ||
      hasDuplicatedGlobalId
    ) {
      result.isValid = false;
      result.message = translate('CaptionResource.ValidateUploadedFile.DuplicatedColumnName');
    }

    return result;
  };

  const onCompleteUpload = () => {
    const showCompleteModal: ShowModalPayload = {
      component: <UploadFileSuccessModal />,
      props: { isBlocking: true, className: 'complete-uploading-modal' },
      style: { display: 'flex', flexFlow: 'column' },
      confirmEvent: () => {},
      title: translate('CommonResource.Uploaded'),
      description: <></>,
      closeIcon: true,
    };
    showModal(showCompleteModal);
  };

  const onUnexpectedError = (ex: any) => {
    const showCompleteModal: ShowModalPayload = {
      component: <UnExpectedErrorModal exception={ex} />,
      props: { isBlocking: true, className: 'unexpected-error-modal' },
      style: { display: 'flex', flexFlow: 'column' },
      confirmEvent: () => {},
      title: translate('CaptionResource.CAPTION_ERROR'),
      description: <></>,
      closeIcon: true,
    };
    showModal(showCompleteModal);
  };

  const onValidatingError = (errors: ValidatedError[], fileName: string, fileContent: any[]) => {
    console.log('errors: ', errors);

    hiddenModal();
    showValidatedErrorModal(errors, fileName, fileContent);
  };

  return (
    <>
      <div className="home-layout-content-title">
        <h2>{translate('Settings.lblSomeTipsBeforeBegin')}</h2>
      </div>
      <div className="home-layout-content-description">
        <div className="description-col">
          <div className="description-col-content">
            <div className="circle-number">1</div>
            <div className="second-text">{translate('CaptionResource.lblDownloadImportFileTemplate')}</div>
            <div className="third-text">{translate('CaptionResource.lblAccessTheTemplate')}</div>
            <div>
              <Link className="link-text" onClick={downloadTemplateExcel}>
                {translate('CaptionResource.Download')}
              </Link>
            </div>
          </div>
          <div className="description-col-content">
            <div className="circle-number">2</div>
            <div className="second-text">{translate('CaptionResource.lblConfirmImportFileAccuracy')}</div>
            <div className="third-text">{translate('CaptionResource.lblEnsureTheFileContent')}</div>
            <div>
              <Link className="link-text" onClick={showInstructionModal}>
                {translate('CaptionResource.ViewDetails')}
              </Link>
            </div>
          </div>
          <div className="description-col-content">
            <div className="circle-number">3</div>
            <div className="second-text">{translate('CaptionResource.lblImportLimits')}</div>
            <div className="third-text">{translate('CaptionResource.lblMaximumFileSize')}</div>
          </div>
        </div>
      </div>
      <div className="home-layout-upload-file">
        <div className="home-layout-content-title">
          <h2>{translate('CaptionResource.lblUploadFile')}</h2>
        </div>
        <div className="upload-file-component">
          <DragAndDropZone
            onChange={onChangeFileStateList}
            acceptFileTypes={acceptExts}
            descriptionValueMaxLength={FileConstants.DocumentUpload.MaxDescriptionLength}
          />
        </div>
      </div>
    </>
  );
};
