import { ApiConstants } from '../core/constants/api.constants';
import { downloadAsync } from './base.service';

export const downloadTemplateExcelFile = async (fileName: string) => {
  const apiUrl = ApiConstants.OnixMakerApi.HandleExcel.DownloadTemplateExcelFile();
  const response = await downloadAsync(apiUrl);

  const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  link.click();
};
