import { ProgressIndicator } from '@fluentui/react';
import { AxiosError, CancelTokenSource } from 'axios';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FileState, FileStateData } from '../../../components/DragAndDropZone/DragAndDropZone';
import { Constants } from '../../../core/constants/constants';
import { TrackingEvent } from '../../../core/constants/tracking-event.constant';
import { readFileContent, removeHyperLink } from '../../../core/utils/fileHelper';
import { globalIdInvalidPrefix, isValidGlobalId } from '../../../core/utils/stringHelper';
import { useTracking } from '../../../hooks/useTracking';
import { ValidatedError } from '../../../models/validated-error.model';
import { uploadExcelFile } from '../../../services/upload-excel.service';
import './index.scss';

interface UploadFileModalProps {
  onCompleteUpload: () => void;
  onUnexpectedError: (props: any) => void;
  onValidatingError: (props: ValidatedError[], fileName: string, fileContent: any[]) => void;
  file: FileState<FileStateData>;
  header: any[];
  cancelTokenSource?: CancelTokenSource;
}

const UploadFileModal = (props: UploadFileModalProps) => {
  const [translate] = useTranslation();
  const [isUploading, setIsUploading] = React.useState(false);
  const [isValidating, setIsValidating] = React.useState(true);
  const [percentComplete, setPercentComplete] = React.useState(0);
  const { trackError } = useTracking();

  const excelHeader = props.header as string[];
  const indexProductNo = excelHeader.findIndex((m) => m.toString().trim() === Constants.ProductNoColumn);
  const indexSerialNo = excelHeader.findIndex((m) => m.toString().trim() === Constants.SerialNoColumn);
  const indexBatchNo = excelHeader.findIndex((m) => m.toString().trim() === Constants.BatchNoColumn);
  const indexOwnerEquipmentId = excelHeader.findIndex((m) => m.toString().trim() === Constants.OwnerEquipmentIdColumn);
  const indexProdYear = excelHeader.findIndex((m) => m.toString().trim() === Constants.ProdYearColumn);
  const indexGlobalId = excelHeader.findIndex((m) => m.toString().trim() === Constants.GlobalIdColumn);

  useEffect(() => {
    const handleCloseTab = (ev: any) => {
      ev.preventDefault();
      return (ev.returnValue = '');
    };
    window.addEventListener('beforeunload', handleCloseTab);
    return () => window.removeEventListener('beforeunload', handleCloseTab);
  }, []);

  useEffect(() => {
    const reasons: ValidatedError[] = [];

    const validateContentFile = async (contentFile: any[], totalEquipment: number) => {
      let resultValidate = true;

      const hasGlobalId = indexGlobalId > -1;
      const hasSerialNo = indexSerialNo > -1;
      const hasBatchNo = indexBatchNo > -1;
      const hasProductNo = indexProductNo > -1;
      const hasOwnerEquipmentId = indexOwnerEquipmentId > -1;
      const hasProdYear = indexProdYear > -1;

      let processedEquipment = 0;
      const globalIds: string[] = [];
      // Index = 1 because ignore header column
      for (let index = 1; index < contentFile.length; index++) {
        const item = contentFile[index];
        let isValidRow = true;
        const errors: string[] = [];

        let serialNo = '';
        let batchNo = '';

        let isEmptyRow = true;
        if (
          (hasProductNo && item[indexProductNo].toString().trim() !== '') ||
          (hasSerialNo && item[indexSerialNo].toString().trim() !== '') ||
          (hasBatchNo && item[indexBatchNo].toString().trim() !== '') ||
          (hasOwnerEquipmentId && item[indexOwnerEquipmentId].toString().trim() !== '') ||
          (hasGlobalId && item[indexGlobalId].toString().trim() !== '') ||
          (hasProdYear && item[indexProdYear].toString().trim() !== '')
        ) {
          isEmptyRow = false;
        }

        if (isEmptyRow) {
          continue;
        }

        if (hasProductNo) {
          const productNoValue = item[indexProductNo].toString().trim();
          if (productNoValue.length > 50) {
            resultValidate = false;
            const error = translate('CaptionResource.ProductNoMaxLengthImportExcel').replace('{0}', item[indexProductNo]);
            isValidRow = false;
            errors.push(error);
          }
          if (productNoValue.length <= 0) {
            resultValidate = false;
            const error = translate('CaptionResource.ProductNoIsRequiredImportExcel');
            isValidRow = false;
            errors.push(error);
          }
        }

        if (hasSerialNo) {
          serialNo = item[indexSerialNo].toString();
          if (serialNo.trim().length > 50) {
            resultValidate = false;
            const error = translate('CaptionResource.SerialNoMaxLengthImportExcel').replace('{0}', serialNo);
            isValidRow = false;
            errors.push(error);
          }
        }

        if (hasBatchNo) {
          batchNo = item[indexBatchNo].toString();
          if (batchNo.trim().length > 30) {
            resultValidate = false;
            const error = translate('CaptionResource.BatchNoMaxLengthImportExcel').replace('{0}', batchNo);
            isValidRow = false;
            errors.push(error);
          }
        }

        if (hasOwnerEquipmentId && item[indexOwnerEquipmentId].toString().trim().length > 50) {
          resultValidate = false;
          const error = translate('CaptionResource.OwnerEquipmentIdMaxLengthImportExcel').replace('{0}', item[indexOwnerEquipmentId]);
          isValidRow = false;
          errors.push(error);
        }

        if (hasProdYear && item[indexProdYear].toString().trim().length > 10) {
          resultValidate = false;
          const error = translate('CaptionResource.ProdYearMaxLengthImportExcel').replace('{0}', item[indexProdYear]);
          isValidRow = false;
          errors.push(error);
        }

        if (serialNo.trim() === '' && batchNo.trim() === '') {
          resultValidate = false;
          const error = translate('CaptionResource.SerialOrBatchRequiredImportExcel');
          isValidRow = false;
          errors.push(error);
        }
        let globalId = '';

        if (hasGlobalId) {
          globalId = item[indexGlobalId].toString().trim();
          if (globalId.length > 0) {
            if (globalIds.filter((m) => m.toString().toLowerCase() === globalId.toLowerCase()).length > 0) {
              resultValidate = false;
              const error = translate('CaptionResource.GlobalIdIsDuplicatedImportExcel').replace('{0}', item[indexGlobalId]);
              isValidRow = false;
              errors.push(error);
            }
            if (!isValidGlobalId(globalId)) {
              resultValidate = false;
              const error = translate('CaptionResource.GlobalIdIsInvalidImportExcel').replace('{0}', item[indexGlobalId]);
              isValidRow = false;
              errors.push(error);
            }
            if (globalIdInvalidPrefix(globalId)) {
              resultValidate = false;
              const error = translate('CaptionResource.GlobalIdInvalidPrefix').replace('{0}', item[indexGlobalId]);
              isValidRow = false;
              errors.push(error);
            }
            globalIds.push(globalId);
          } else {
            resultValidate = false;
            const error = translate('CaptionResource.GlobalIdIsRequiredImportExcel').replace('{0}', item[indexGlobalId]);
            isValidRow = false;
            errors.push(error);
          }
        }

        const reason = new ValidatedError(index, errors, isValidRow);
        reasons.push(reason);
        processedEquipment++;

        const newProgress = processedEquipment / totalEquipment;
        setPercentComplete(newProgress);
      }

      return resultValidate;
    };

    const uploadFile = async () => {
      const [fileData, totalEquipment] = await readFileContent(props.file);
      const resultValidate = await validateContentFile(fileData, totalEquipment);
      setIsValidating(false);
      console.log('reasons: ', reasons);
      const hasInvalid = reasons.filter((m) => !m.isValid).length > 0;
      if (hasInvalid) {
        props.onValidatingError(reasons, props.file.description, fileData);
      }
      if (resultValidate) {
        setIsUploading(true);
        const fileFinally = await removeHyperLink(props.file.file);
        const formData = new FormData();
        formData.append('FormFile', fileFinally);
        try {
          await uploadExcelFile(formData, props?.cancelTokenSource);
          setPercentComplete(1);
          props.onCompleteUpload();
        } catch (ex) {
          const error = ex as AxiosError;
          if (error.message !== Constants.cancelUploadFile) {
            trackError(TrackingEvent.UnExpectedError, TrackingEvent.UnExpectedError);
            props.onUnexpectedError(ex);
          }
        }
      }
    };

    uploadFile();
  }, []);

  return (
    <div className="body-progress">
      {isValidating && (
        <ProgressIndicator
          label={translate('CaptionResource.progressBarTitleValidating')}
          description={translate('CaptionResource.progressBarDescriptionValidating')}
          percentComplete={percentComplete}
        />
      )}
      {isUploading && (
        <ProgressIndicator
          label={translate('AppInspect.Uploading')}
          description={translate('CaptionResource.progressBarDescriptionUploading')}
        />
      )}
    </div>
  );
};

export default UploadFileModal;
