import { Callout, Icon } from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';

interface IProcessingCalloutProps extends HTMLAttributes<HTMLElement> {
  itemId: number | undefined;
}

const ProcessingCallout = (props: IProcessingCalloutProps) => {
  const { itemId } = props;
  const [translate] = useTranslation();
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
  const buttonId = useId(`callout-button-${itemId}`);
  const labelId = useId(`callout-label-${itemId}`);
  const descriptionId = useId(`callout-description-${itemId}`);
  return (
    <>
      <Icon className='processing-icon' id={buttonId} onClick={toggleIsCalloutVisible} styles={{ root: { fontSize: '16px', color: 'grey' } }} iconName={'Info'}></Icon>
      {isCalloutVisible && (
        <Callout
          className={'callout-container'}
          ariaLabelledBy={labelId}
          ariaDescribedBy={descriptionId}
          role="dialog"
          gapSpace={0}
          target={`#${buttonId}`}
          onDismiss={toggleIsCalloutVisible}
          setInitialFocus
        >
          <div className="callout-title">{translate('CommonResource.ProcessingCalloutTitle')}</div>
          <div className="callout-description"> {translate('CommonResource.ProcessingCalloutNotification')}</div>
        </Callout>
      )}
    </>
  );
};

export default ProcessingCallout;
