export const RouteConstants = {
  NoAccess: 'no-access',
  UploadHistory: 'upload-history',
  Home: 'home',
  Login: 'login',
  AuthCallBack: 'auth-callback',
  Logout: 'logout',
  PreviewFile: 'preview-files',

  Others: '*',
};
