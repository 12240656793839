import { Icon, PrimaryButton } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { ChangeEvent, DragEvent, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FileType } from '../../common/enums/file-type.enum';
import CommonHelper from '../../common/helpers/common-helper';
import './index.scss';

export interface FileState<T = any> {
  /**
   * Selected file.
   */
  file: File;

  /**
   * Selected file.
   */
  fileCompression?: File;

  /**
   * File description.
   *
   * @defaultValue is current file name.
   */
  description: string;

  /**
   * File type. This can be undefined because it is not in the supported extension list.
   */
  readonly type: FileType;

  /**
   * Formatted file size in string.
   */
  formattedSize: string;

  /**
   * For custom data.
   */
  data?: T;
}

export interface DragAndDropZoneProps<T = any> {
  /**
   * Multiple mode.
   */
  multiple?: boolean;

  /**
   * List of accepted mime types, separated by comma.
   */
  acceptFileTypes?: string;

  /**
   * Handle onChange event.
   */
  onChange?: (fs: FileState<T>[]) => void;

  /**
   * Set upload zone readonly.
   */
  readOnly?: boolean;

  /**
   * Maximum default description value length.
   */
  descriptionValueMaxLength?: number;

  isDisable?: boolean;
}

export enum UploadState {
  New,
  Uploaded,
  UploadFailed,
}

export interface FileStateData {
  type: string;
  isValid: boolean;
  isExist: boolean;
  uploadState: UploadState;
}

// Use function because highlight formatter is broken when using generic arrow function.
/**
 * A drag and drop zone for browsing and dropping files
 *
 * Current behavior will exclude all folders.
 */
export function DragAndDropZone<T = any>(props: DragAndDropZoneProps<T>) {
  const ref = useRef<HTMLInputElement>(null);
  const [translate] = useTranslation();
  const [isDragActive, { setTrue: setDragActive, setFalse: setDragInactive }] = useBoolean(false);
  const containerClassName = 'drag-and-drop-zone' + (isDragActive ? ' drag-active' : '') + (props?.isDisable ? ' drag-disabled' : '');

  const browseBtnClick = useCallback(() => {
    ref.current?.click();
  }, []);

  const onReceiveFiles = useCallback(
    (fileList: FileList | File[] | null) => {
      // There is nothing to do when no onChange callback is provided.
      if (!props.onChange) {
        return;
      }

      const files = Array.from(fileList ?? []);
      if (!files?.length) {
        props.onChange([]);
        return;
      }

      const fileStates = files.map(
        (file) =>
          ({
            file,
            description: file.name.trim().substring(0, props.descriptionValueMaxLength), // if props.descriptionValueMaxLength is undefined
            formattedSize: CommonHelper.sizeToString(file.size),
            type: CommonHelper.getFileTypeFromFileName(file.name),
          } as FileState)
      );

      props.onChange(fileStates);
    },
    [props]
  );

  const onInputFileChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onReceiveFiles(event.target.files);

      // reset current input files so it can add the same file again.
      if (ref.current) {
        ref.current.value = '';
      }
    },
    [onReceiveFiles]
  );

  const handleDrag = useCallback(
    (event: DragEvent) => {
      event.preventDefault();
      event.stopPropagation();

      if (props.readOnly) {
        return;
      }

      switch (event.type) {
        case 'dragenter':
        case 'dragover':
          setDragActive();
          break;

        case 'dragleave':
          setDragInactive();
          break;
      }
    },
    [props.readOnly, setDragActive, setDragInactive]
  );

  const handleDrop = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setDragInactive();

      // Filter out folders.
      const files = Array.from(event.dataTransfer.items)
        .filter(CommonHelper.isFile)
        .map((item) => item.getAsFile())
        .filter(CommonHelper.isNotNullOrUndefined);

      onReceiveFiles(files);
    },
    [onReceiveFiles, setDragInactive]
  );

  return (
    <div className={containerClassName} onDragEnter={handleDrag}>
      <input
        ref={ref}
        type="file"
        className="select-file-input"
        multiple={props.multiple}
        accept={props.acceptFileTypes}
        onChange={onInputFileChange}
        readOnly={props.readOnly || props?.isDisable}
      />
      <div className="upload-icon">
        <Icon iconName="Upload" />
      </div>
      <div className="upload-message">{translate('CommonResource.DragAndDropMessage')}</div>
      <div className="upload-message">{translate('CommonResource.lblOr')}</div>
      <div>
        <PrimaryButton text={translate('Settings.lblBrowse')} onClick={browseBtnClick} disabled={props?.isDisable} />
      </div>

      {isDragActive && (
        // This dummy element is for covering the whole component to handle dragenter, dragleave, dragover to prevent flashing.
        <div className="dummy-element" onDragEnter={handleDrag} onDragOver={handleDrag} onDragLeave={handleDrag} onDrop={handleDrop}></div>
      )}
    </div>
  );
}
