import { ModalActions, ShowModalPayload } from '../states/modal/modalSlice';
import { appDispatch } from '../states/store';

const dispatch = appDispatch;

export const showModal = (request: ShowModalPayload) => {
  dispatch(ModalActions.showModal(request));
};

export const hiddenModal = () => {
  dispatch(ModalActions.hiddenModal());
};
