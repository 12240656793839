import { LanguageEnum } from '@onix/common';
import { ApiConstants } from '../core/constants/api.constants';
import { UserAccount } from '../models/user-account.model';
import { ExpressActions } from '../states/express/expressSlice';
import { OrganizationActions } from '../states/organization/organizationSlice';
import { appDispatch } from '../states/store';
import { getAsync, postAsync, putAsync } from './base.service';

const dispatch = appDispatch;

export const getAccountInfoSuggestion = (request: any) => {
  const url = ApiConstants.OnixMakerApi.Account.GetSuggestion();
  return getAsync(url, request);
};

export const getAccountInfoSuggestionOnixExpress = (request: any) => {
  const url = ApiConstants.OnixMakerApi.Account.GetSuggestionOnixExpress();
  return getAsync(url, request);
};

export const createAccount = (request: any) => {
  const url = ApiConstants.OnixMakerApi.Account.CreateAccount();
  return postAsync(url, request);
};

export const createAccountOnixExpress = (request: any) => {
  const url = ApiConstants.OnixMakerApi.Account.CreateAccountOnixExpress();
  return postAsync(url, request);
};

export const getInformationToResetPassword = (request: any) => {
  const url = ApiConstants.OnixMakerApi.Account.GetInfoToResetPassword();
  return postAsync(url, request);
};

export const getInformationToResetPasswordOnixExpress = (request: any) => {
  const url = ApiConstants.OnixMakerApi.Account.GetInfoToResetPasswordOnixExpress();
  return postAsync(url, request);
};

export const resetPassword = (request: any) => {
  const url = ApiConstants.OnixMakerApi.Account.ResetPassword();
  return putAsync(url, request);
};

export const resetPasswordOnixExpress = (request: any) => {
  const url = ApiConstants.OnixMakerApi.Account.ResetPasswordOnixExpress();
  return putAsync(url, request);
};

export const getInformation = () => {
  const url = ApiConstants.OnixMakerApi.Account.GetInformation();
  return getAsync(url);
};

export const updateAvatarPicture = (request: any) => {
  const url = ApiConstants.OnixMakerApi.Account.UpdateAvatarPicture();
  return putAsync(url, request);
};

export const updateSignaturePicture = (request: any) => {
  const url = ApiConstants.OnixMakerApi.Account.UpdateSignature();
  return putAsync(url, request);
};

export const updateInformation = (request: UserAccount): Promise<UserAccount> => {
  const url = ApiConstants.OnixMakerApi.Account.UpdateInformation();
  return putAsync(url, request);
};

export const updatePreferredLanguage = (languageId: LanguageEnum) => {
  const url = ApiConstants.OnixMakerApi.Account.UpdatePreferredLanguage();
  return putAsync(url, { languageId });
};

export const updateMainCompany = (contactId: number) => {
  const url = ApiConstants.OnixMakerApi.Account.UpdateMainCompany(contactId);
  return putAsync(url);
};

export const getMemberCompanyInvitations = async (request: any) => {
  const url = ApiConstants.OnixMakerApi.Account.GetMemberCompanyInvitation();
  const res = await getAsync(url, request);
  dispatch(OrganizationActions.updateInvitations(res));
  return res;
};

export const getExpressCompanyInvitations = async () => {
  const url = ApiConstants.OnixMakerApi.Account.GetExpressCompanyInvitation();
  const openInvitations = await getAsync(url);
  dispatch(ExpressActions.updateInvitations(openInvitations));
  return openInvitations;
};

export const createMemberUserByInvitation = (invitationId: number) => {
  const url = ApiConstants.OnixMakerApi.Account.CreateMemberUserByInvitation(invitationId);
  return postAsync(url);
};

export const createExpressUserByInvitation = (invitationId: number) => {
  const url = ApiConstants.OnixMakerApi.Account.CreateExpressUserByInvitation(invitationId);
  return postAsync(url);
};

export const sendResetPasswordEmail = () => {
  const url = ApiConstants.Identity.Account.SendResetPasswordEmail();
  return postAsync(url);
};
