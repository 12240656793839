import { environment } from '../../environments/environment';
import { environment as beta } from '../../environments/environment.beta';
import { environment as prod } from '../../environments/environment.prod';
import { environment as sandbox } from '../../environments/environment.sandbox';
import { environment as test } from '../../environments/environment.test';
import { ApplicationSettingInterface } from '../../models/application-setting.model';

const configs = {
  dev: environment,
  beta: beta,
  test: test,
  sandbox: sandbox,
  prod: prod,
} as any;

const ApplicationSetting: ApplicationSettingInterface = configs[process.env.REACT_APP_STATE || 'dev'];

export default ApplicationSetting;

export const ApplicationVersion = process.env.REACT_APP_VERSION || '1.0.0';
