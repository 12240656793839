import { HttpClient } from '@onix/common';
import { StatusCodes } from 'http-status-codes';
import { SettingKeyConstants } from '../core/constants/setting-key.constants';
import { AppActions } from '../states/app/appSlice';
import { appDispatch } from '../states/store';
import { clearToken, login } from './authentication.service';
import { generateLocalKeyByName } from './common.service';
import { CancelTokenSource } from 'axios';

const dispatch = appDispatch;
const appActions = AppActions;

export const getAsync = (url: string, request: any = {}, isLoading: boolean = true, headers: any = getAuthorizationHeaders()) => {
  detectShowLoading(isLoading);
  return HttpClient.getAsync(url, request, headers)
    .catch(async (err: any) => {
      return handleError(err);
    })
    .finally(() => {
      detectHiddenLoading(isLoading);
    });
};

export const downloadAsync = async (url: string) => {
  try {
    return await HttpClient.downloadAsync(url);
  } catch (err) {
    return await handleError(err);
  }
};

export const postAsync = (
  url: string,
  request: any = {},
  isLoading: boolean = true,
  headers: any = getAuthorizationHeaders(),
  cancelTokenSource?: CancelTokenSource
) => {
  detectShowLoading(isLoading);
  return HttpClient.postAsync(url, request, headers, undefined, cancelTokenSource)
    .catch(async (err: any) => {
      return handleError(err);
    })
    .finally(() => {
      detectHiddenLoading(isLoading);
    });
};

export const putAsync = (url: string, request: any = {}, isLoading: boolean = true, headers: any = getAuthorizationHeaders()) => {
  detectShowLoading(isLoading);
  return HttpClient.putAsync(url, request, headers)
    .catch(async (err: any) => {
      return handleError(err);
    })
    .finally(() => {
      detectHiddenLoading(isLoading);
    });
};

export const deleteAsync = (url: string, isLoading: boolean = true, headers: any = getAuthorizationHeaders()) => {
  detectShowLoading(isLoading);
  return HttpClient.deleteAsync(url, headers)
    .catch(async (err: any) => {
      return await handleError(err);
    })
    .finally(() => {
      detectHiddenLoading(isLoading);
    });
};

function getAuthorizationHeaders() {
  const token = getToken();
  return {
    Authorization: `Bearer ${token}`,
  };
}

const detectShowLoading = (isLoading: boolean) => {
  if (isLoading) {
    dispatch(appActions.showLoading());
  }
};

const detectHiddenLoading = (isLoading: boolean) => {
  if (isLoading) {
    dispatch(appActions.hiddenLoading());
  }
};

const getToken = () => {
  return (
    sessionStorage.getItem(generateLocalKeyByName(SettingKeyConstants.AccessToken)) ||
    localStorage.getItem(generateLocalKeyByName(SettingKeyConstants.AccessToken)) ||
    ''
  );
};

const handleError = async (response: any) => {
  if (response?.status === StatusCodes.UNAUTHORIZED) {
    clearToken();
    await login();
    return;
  }

  if (response?.data) {
    return Promise.reject(response.data);
  }
  return Promise.reject(response);
};
