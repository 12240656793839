import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RouteConstants } from '../../core/constants/routes.constants';
import { userSelector } from '../../states/user/userSlice';
import './index.scss';

export const Denied = () => {
  const { isGlobalAdmin, onixMakerCanEdit, companyName, disabled, totalCompany } = useSelector(userSelector);
  const [translate] = useTranslation();
  const navigation = useNavigate();
  const deniedText = useMemo(() => {
    if (totalCompany === 0) {
      return translate('CommonResource.HasNoOrg');
    } else {
      if (disabled) {
        return translate('CommonResource.AccountDisabledForCompany').replace('{0}', companyName);
      } else {
        return translate('CommonResource.NotHaveAccessForCompany').replace('{0}', companyName);
      }
    }
  }, [companyName, disabled, totalCompany, translate]);

  useEffect(() => {
    if (isGlobalAdmin || onixMakerCanEdit) {
      navigation(`${RouteConstants.Home}`);
    }
  }, [isGlobalAdmin, onixMakerCanEdit, navigation]);

  return (
    <div className="denied-page-container">
      <div dangerouslySetInnerHTML={{ __html: deniedText.replace(/<br>/g, '<br/>') }} />
    </div>
  );
};
