import { SupportedLanguageConstants } from '@onix/common/src/constants/language.constants';
import i18next from 'i18next';
import ApplicationSetting from '../core/constants/application.constants';

export const getCurrentLanguage = () => {
  return i18next.language || 'en';
};

export const generateLocalKeyByName = (name: string) => {
  return generateLocalKey(name);
};

export const getSupportedLanguages = () => {
  return SupportedLanguageConstants.map((language) => {
    return {
      id: language.id,
      name: language.name,
      code: language.code,
    };
  });
};

const generateLocalKey = (key: string) => {
  return '{appId}.{env}.{key}'
    .replace('{appId}', ApplicationSetting.clientApplicationId.toString())
    .replace('{env}', ApplicationSetting.name)
    .replace('{key}', key);
};

export const isAndroid = () => {
  return /android/i.test(navigator.userAgent);
};

export const isiOS = () => {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

export const CommonService = {
  generateLocalKey
};
