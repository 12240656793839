import { FileConstants } from '../constants/file.constant';
import { FileType } from '../enums/file-type.enum';

const formatNumberFn = (num: number, maxDecimalPlaces: number = 1): number => parseFloat(num.toFixed(maxDecimalPlaces));

const sizeToString = (
  size: number,
  formatOptions?: {
    separator?: string;
    decimalPlaces?: number;
  }
): string => {
  const sep = formatOptions?.separator ?? ' ';
  const decimalPlaces = formatOptions?.decimalPlaces ?? 1;

  const KB = FileConstants.Size.KB;
  const MB = FileConstants.Size.MB;

  if (size > MB.size) {
    const sizeInMB = size / MB.size;
    const formattedSize = formatNumberFn(sizeInMB, decimalPlaces);
    return `${formattedSize}${sep}${MB.name}`;
  }

  const sizeInKB = size / KB.size;
  const formattedSize = formatNumberFn(sizeInKB, decimalPlaces);
  return `${formattedSize}${sep}${KB.name}`;
};

const getFileExtension = (fileName: string): string | undefined => {
  const splitted = fileName.split('.');
  if (splitted[0] === fileName) {
    return undefined;
  }

  return splitted.pop();
};

const getFileTypeFromFileName = (fileName: string): FileType => {
  const ext = getFileExtension(fileName)?.toLowerCase();
  if (!ext) {
    return FileType.Unsupported;
  }

  const extType = Object.values(FileConstants.Exts).find((e) => e.exts.includes(ext));
  if (!extType) {
    return FileType.Unsupported;
  }

  return extType.type;
};

const isNotNullOrUndefined = <T>(value: T | null | undefined): value is T => value !== null && value !== undefined;

const isFile = (item: DataTransferItem): boolean => {
  // Function getAsEntry may be standalized in the future;
  // Read more: https://developer.mozilla.org/en-US/docs/Web/API/DataTransferItem/webkitGetAsEntry
  if ((item as any).getAsEntry) {
    return !!(item as any).getAsEntry()?.isFile;
  } else if (item.webkitGetAsEntry) {
    return !!item.webkitGetAsEntry()?.isFile;
  }

  return false;
};

const CommonHelper = {
  /**
   * Format size in bytes to string as KB/MB.
   */
  sizeToString,

  /**
   * Parse file extension and get file type.
   */
  getFileTypeFromFileName,

  /**
   * Small helper function intended to use with Array.filter to filter out null/undefined values from an array.
   */
  isNotNullOrUndefined,

  /**
   * Check if a `DataTransferItem` is a file or not. This is used along side with file dragging.
   */
  isFile,
};

export default CommonHelper;
