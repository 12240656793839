export const SettingKeyConstants = {
    AccessToken: 'AccessToken',
    RefreshToken: 'RefreshToken',
    Language: 'Language',
    DownloadScopeSelected: 'DownloadScopeSelected',
    LocalDataPath: 'LocalDataPath',
    WorkingLocation: 'WorkingLocation',
    LicenseKey: 'LicenseKey',
    UserInfo: 'UserInfo',
    RememberMe: 'RememberMe',
    JobInformation: 'JobInformation',
    LatestUploadInfo: 'LatestUploadInfo',
    RefreshCredential: 'RefreshCredential',
    EnableRefreshCredential: 'EnableRefreshCredential',
    Environment: 'Environment',
    DownloadedDocumentCount: 'DownloadedDocumentCount',
    DocumentFolderLocation: 'DocumentFolderLocation',
    PackageId: 'PackageId',
    DownloadedPictureCount: 'DownloadedPictureCount',
    AutoDownloadPictureWhenViewing: 'AutoDownloadPictureWhenViewing',
    RedirectRoute: 'RedirectRoute',
    NavigationExtras: 'NavigationExtras',
    InputAlias: 'InputAlias',
    AuthenticatedUserInfo: 'AuthenticatedUserInfo',
    CompanyId: 'CompanyId',
    AuthenTypes: 'AuthenTypes',
}