import {
  CheckboxVisibility,
  ColumnActionsMode,
  DefaultButton,
  Dropdown,
  IColumn,
  IDropdownOption,
  PrimaryButton,
  Selection,
  SelectionMode,
} from '@fluentui/react';
import { CustomSearchBox, OnixTable } from '@onix/common';
import { IOnixTablePagination, IOnixTableRequest } from '@onix/common/src/components/OnixTable/IOnixTable';
import { FormEvent, createContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { TableColumnConstant } from '../../../../core/constants/table-column.constant';
import { useTable } from '../../../../hooks/useTable';
import { Organization } from '../../../../models/organization.model';
import { getCurrentAuthenticationService } from '../../../../services/authentication.service';
import { hiddenModal } from '../../../../services/modal.service';
import { getMemberOrganization } from '../../../../services/organization.service';
import { AppActions } from '../../../../states/app/appSlice';
import { appDispatch } from '../../../../states/store';
import { userSelector } from '../../../../states/user/userSlice';
import './index.scss';
import { OnixAccessStatusEnum } from '../../../../core/constants/enums/onix-access-status.enum';

const Column = {
  CompanyName: 'companyName',
  Type: 'companyType',
  Role: 'role',
  AccessStatus: 'accessStatus',
};

const COMPANY_DEFAULT_VALUE = 'All';

export const ProductContext = createContext({
  selectedArticles: [] as any[],
  searchText: '' as string,
});

export const CompanyDataAccessConstants = {
  state: {
    Granted: {
      title: 'Settings.AccessGranted',
      titleClass: 'granted',
    },
    Restricted: {
      title: 'Settings.AccessRestricted',
      titleClass: 'restricted',
    },
    PendingConsent: {
      title: 'Settings.AccessPendingConsent',
      titleClass: 'pendingconsent',
    },
  }
};

export type OnixAccessStatusValue = keyof typeof OnixAccessStatusEnum;

export const Company = () => {
  const [translate] = useTranslation();
  const user = useSelector(userSelector);
  const [selectedOrganization, setSelectedOrganization] = useState<Organization>();
  const [searchText, setSearchText] = useState<string>('');
  const [companyType, setCompanyType] = useState<string>('');
  const [id, setId] = useState<string>('');
  const navigation = useNavigate();

  const authenticationService = getCurrentAuthenticationService('1');
  const dispatch = appDispatch;

  const [selection] = useState(
    new Selection({
      onSelectionChanged: () => {
        const organization = selection.getSelection() as Organization[];
        if (organization.length > 0) {
          setSelectedOrganization(organization[0]);
        } else {
          setSelectedOrganization(undefined);
        }
      },
      selectionMode: SelectionMode.single,
      canSelectItem: (item: any) => {
        return item.accessStatus !== OnixAccessStatusEnum.Restricted;
      },
    })
  );

  const companyTypes = useMemo((): IDropdownOption[] => {
    let options: IDropdownOption[] = [{ key: COMPANY_DEFAULT_VALUE, text: translate('CaptionResource.Caption64') }];
    user.companyTypes.forEach((type) => {
      options.push({ key: type, text: type });
    });
    return options;
  }, []);

  const { onRenderCell } = useTable({});

  const dependencyArrayToGetItems = useMemo(() => {
    return [id];
  }, [id]);

  const columns = useMemo((): IColumn[] => {
    const createColumn = (
      key: string,
      name: string,
      maxWidth: number = TableColumnConstant.StandardColumnDimension,
      minWidth: number = TableColumnConstant.SmallColumnDimension,
      onRender: (item?: any, column?: IColumn) => JSX.Element | null = onRenderCell
    ): IColumn => {
      return {
        key,
        name,
        fieldName: key,
        minWidth: minWidth,
        maxWidth,
        isResizable: true,
        columnActionsMode: ColumnActionsMode.disabled,
        onRender: (item, _, column) => onRender(item, column),
      };
    };

    let cols = [
      createColumn(Column.CompanyName, translate('CaptionResource.Caption0')),
      createColumn(Column.Role, translate('Settings.Role'), TableColumnConstant.StandardColumnDimension, TableColumnConstant.SmallColumnDimension),
    ];
    if (user.isGlobalAdmin) {
      cols.splice(1, 0, createColumn(Column.Type, translate('CaptionResource.Caption5'), TableColumnConstant.SmallColumnDimension));
      cols.push(
        createColumn(
          Column.AccessStatus,
          translate('Settings.AccessStatus'),
          TableColumnConstant.SmallColumnDimension,
          TableColumnConstant.SmallColumnDimension,
          (item, column) =>
            onRenderCell(
              item,
              column,
              renderOnixAccess(item.accessStatus),
            )
        )
      )
    }
    return cols;
  }, []);

  const renderOnixAccess = (accessStatus: number) => {
    var status = OnixAccessStatusEnum[accessStatus] as OnixAccessStatusValue;
    var dataAccess = CompanyDataAccessConstants.state[status];

    return (
      <div className={dataAccess.titleClass}>
        <span>{translate(dataAccess.title)}</span>
      </div>
    );
  }

  const getCompanies = async (request: IOnixTableRequest) => {
    const payload = {
      textSearch: searchText,
      companyType: companyType,
      pageIndex: request.pageNumber !== undefined && request.pageNumber > 0 ? request.pageNumber : 1,
      pageSize: 100,
    };

    const organizations = await getMemberOrganization(payload);
    return {
      isLastPage: organizations.isLastPage,
      items: organizations.items,
      pageNumber: organizations.pageIndex,
      pageSize: 100,
      totalItems: organizations.totalItems,
    } as IOnixTablePagination;
  };

  const onCloseCompanyModal = () => {
    hiddenModal();
  };

  const switchOrganization = async () => {
    if (selectedOrganization != null) {
      var res = await authenticationService?.switchingOrganization(selectedOrganization.companyId);

      if (!res.isSuccess) {
      } else {
        dispatch(AppActions.triggerReloadUserInfo());
        navigation('/home');
        hiddenModal();
      }
    } else {
      hiddenModal();
    }
  };

  const onChangeCompanyType = (e: FormEvent<HTMLDivElement>, selected: IDropdownOption<any> | undefined) => {
    if (selected !== undefined) {
      var selectedType = selected.key.toString();
      setCompanyType(selectedType === COMPANY_DEFAULT_VALUE ? '' : selectedType);
    }
  };

  const onSearch = (newVal: string | undefined) => {
    setSearchText(newVal || '');
    const id = uuid();
    setId(id);
  };

  const onClear = () => {
    setSearchText('');
    const id = uuid();
    setId(id);
  };

  return (
    <>
      <div className="company-search">
        {user.isGlobalAdmin && (
          <Dropdown
            className="dropdown-type"
            onChange={(e, i) => onChangeCompanyType(e, i)}
            options={companyTypes}
            defaultSelectedKey={COMPANY_DEFAULT_VALUE}
          ></Dropdown>
        )}
        <CustomSearchBox
          classNames="search-box"
          placeholder={translate('CaptionResource.OtherOrgSearchPlaceholder')}
          onSearch={(newValue) => onSearch(newValue)}
          onClear={() => onClear()}
        ></CustomSearchBox>
      </div>
      <div className="company-content-body">
        <OnixTable
          compact
          columns={columns}
          selection={selection}
          translate={translate}
          predicateToGetKey={(org: Organization) => org.companyId}
          onGetItems={getCompanies}
          dependencyArrayToGetItems={dependencyArrayToGetItems}
          checkboxVisibility={CheckboxVisibility.always}
          onRenderRow={(props, defaultRender) => {
            const isDisabled = props?.item.accessStatus === OnixAccessStatusEnum.Restricted;
              return (
                <div className={isDisabled ? 'disabled-onix-access' : ''}>
                  {defaultRender?.(props)}
                </div>
              );
          }}
        ></OnixTable>
      </div>
      <div className="company-footer">
        <PrimaryButton text={translate('CommonResource.cmdOk')} onClick={switchOrganization} />
        <DefaultButton text={translate('CommonResource.Cancel')} onClick={onCloseCompanyModal} />
      </div>
    </>
  );
};
