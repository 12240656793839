import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import './index.scss';
import { FileConstants } from '../../common/constants/file.constant';
import { buildEncodeFileDetailUrl } from '../../core/utils/fileHelper';

const PreviewFile = () => {
  const [fileContent, setFileContent] = useState<string>('');
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    const id = searchParams.get('id') ?? '';
    const isError = searchParams.get('isError') === 'true';

    const file = `${FileConstants.MICROSOFT_DOCUMENT_VIEWER_URL}${buildEncodeFileDetailUrl(id, isError)}`;
    setFileContent(file);
  }, []);

  return (
    <div className="file-preview-container">
      {fileContent !== '' && <iframe title="excelFileFrame" className="frame-excel-content" src={fileContent}></iframe>}
    </div>
  );
};

export default PreviewFile;
