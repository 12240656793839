export enum AuthenTypes {
  OIDC = 1,
}

export enum AuthenState {
  Loading = 'Loading',
  NotFound = 'NotFound',
  SingOut = 'SingOut',
  MainLayout = 'MainLayout',
  NoAccess = 'NoAccess',
  NotAvailable = 'NotAvailable',
}
