import { useTranslation } from 'react-i18next';
import './index.scss';
import { MessageBar } from '@fluentui/react';

export default function CompanyDescription() {
  const [translate] = useTranslation();

  return (
    <div className="organization-content-description">
      <MessageBar>
        <div className="content">{translate('CaptionResource.BannerSwitchOrgInfoNormal')}</div>
      </MessageBar>
    </div>
  );
}
