import moment from 'moment';
import { DateTimeFormatConstants } from '../constants/dateTime.constant';

const formatDate = (date: any, format = DateTimeFormatConstants.momentDateFormat) => (date ? moment(date).format(format) : date);

const formatDateTime = (date: any, format = DateTimeFormatConstants.momentDateTimeFormat) => (date ? moment(date).format(format) : date);

const getTimeLeft = () => {
  var now = moment();
  var deadline = now.clone().hour(0).minute(0).second(0);
  if (now.isAfter(deadline)) {
    var tomorrow = moment(new Date()).add(1, 'days').hour(0).minute(0).second(0);
    return tomorrow.diff(now, 'hours') + ' hrs ' + (tomorrow.diff(now, 'minutes') % 60) + ' mins';
  } else {
    return deadline.diff(now, 'hours') + ' hrs, ' + (deadline.diff(now, 'minutes') % 60) + ' mins';
  }
};

export const DateTimeHelper = {
  formatDate,
  formatDateTime,
  getTimeLeft,
};
