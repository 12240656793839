import { ApiConstants } from '../core/constants/api.constants';
import { UploadFile } from '../models/upload-history.model';
import { getAsync } from './base.service';

export const getUploadHistory = (request: any): Promise<{items: UploadFile[], pageIndex: number, totalItems: number, isLastPage: boolean}> => {
  const url = ApiConstants.OnixMakerApi.UploadHistory.GetUploadHistory();
  return getAsync(url, request);
};

export const downloadFileDetail = (request: any) => {
  const url = ApiConstants.OnixMakerApi.UploadHistory.DownloadFileDetail();
  return getAsync(url, request);
};
