import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { CommonService } from '../../services/common.service';
import ApplicationSetting from './application.constants';
import { ClientApplication } from './enums/client-application.enum';
import { getUserInfo } from '../../services/authentication.service';

const reactPlugin = new ReactPlugin();
const ApplicationInsightBufferKey = CommonService.generateLocalKey(ApplicationSetting.applicationInsightKey);

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: ApplicationSetting.applicationInsightKey,
    extensions: [reactPlugin as any],
    extensionConfig: {},
    enableAutoRouteTracking: false,
    disableAjaxTracking: true,
    autoTrackPageVisitTime: false,
    bufferOverride: {
      getItem(_, ___) {
        return localStorage.getItem(ApplicationInsightBufferKey) || '';
      },
      setItem(_, __, data) {
        localStorage.setItem(ApplicationInsightBufferKey, data);
        return true;
      },
    },
  },
});
appInsights.loadAppInsights();
appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
  env.tags = env.tags || [];
  env.tags['ai.operation.id'] = ClientApplication.OnixMaker;
  env.tags['ai.operation.name'] = 'Onix Maker';

  // TODO get information from user account logged at here
  const userInfo = JSON.parse(getUserInfo());
  env.tags['ai.user.id'] = userInfo.contactId;

  env.tags['ai.user.authUserId'] = userInfo.user_accountid;
  env.tags['ai.user.accountId'] = userInfo.user_accountid;
});

export { appInsights, reactPlugin };
