import { useTranslation } from 'react-i18next';
import './index.scss';

const UnExpectedErrorModal = (props: any) => {
  const [translate] = useTranslation();
  const { stacktrace, timeStamp, messageTitle } = props.exception;
  return (
    <div className="unexpected-container">
      <div className="error-title">{translate('AppInspect.SomethingWentWrong')}</div>
      <div className="error-content">{translate('CommonResource.UnExpectedIndicator')}</div>
      <div className="error-additional-info">
        {timeStamp && <div>{`${translate('CommonResource.Timestamp')} ${timeStamp}`}</div>}
        {messageTitle && <div>{`${translate('CommonResource.ExceptionDetails')} ${messageTitle}`}</div>}
        {stacktrace && <div>{translate('CommonResource.StackTrace')}</div>}
      </div>
      {stacktrace && <div className="stack-trace">{stacktrace}</div>}
    </div>
  );
};

export default UnExpectedErrorModal;
