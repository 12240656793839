import { Icon } from '@fluentui/react';
import { HistoryStatusEnum } from '../../../core/constants/enums/history-status.enum';
import './index.scss';
import { useTranslation } from 'react-i18next';
import { HTMLAttributes, useCallback, useEffect, useMemo, useState } from 'react';
import ProcessingCallout from './ProcessingCallout/ProcessingCallout';
import { UploadFile } from '../../../models/upload-history.model';

interface IStatusHistoryProps extends HTMLAttributes<HTMLElement> {
  item: UploadFile | undefined;
  hasIcon?: boolean;
  viewDetail?: (fileId: number | undefined, isError: boolean) => void | undefined;
}

const StatusHistory = (props: IStatusHistoryProps) => {
  const { item, hasIcon, viewDetail } = props;
  const { status, totalFailedEquipment, errorPathName } = item as UploadFile;
  const [translate] = useTranslation();
  const [itemFailed, setItemFailed] = useState(false);

  const viewErrorDetail = useCallback(() => {
    if (viewDetail) {
      viewDetail(item?.taskQueueId, true);
    }
  }, [item?.taskQueueId, viewDetail]);

  const renderStatus = useMemo(() => {
    let mappedStatus = 'Processing';
    if (status === HistoryStatusEnum.Completed && errorPathName !== null) {
      mappedStatus = translate('CommonResource.FailedWithItems').replace('{0}', totalFailedEquipment.toString());
    } else if (status === HistoryStatusEnum.Completed) {
      mappedStatus = translate('RibbonMenu.Done');
    } else if (status === HistoryStatusEnum.Processing) {
      mappedStatus = translate('CommonResource.UploadHistory.Processing');
    } else if (status === HistoryStatusEnum.Failed) {
      mappedStatus = translate('CommonResource.lblFailed');
    }
    return mappedStatus;
  }, [status, errorPathName, totalFailedEquipment, translate]);

  useEffect(() => {
    const itemFailed = status === HistoryStatusEnum.Failed || (status === HistoryStatusEnum.Completed && errorPathName !== null);
    setItemFailed(itemFailed);
  }, [status, errorPathName]);

  const renderIcon = useMemo(() => {
    let iconName = '';
    let color = '';
    if (status === HistoryStatusEnum.Failed || (status === HistoryStatusEnum.Completed && errorPathName !== null)) {
      iconName = 'ErrorBadge';
      color = 'red';
    } else if (status === HistoryStatusEnum.Completed) {
      iconName = 'SkypeCircleCheck';
      color = 'green';
    } else if (status === HistoryStatusEnum.Processing && hasIcon) {
      return <ProcessingCallout itemId={item?.taskQueueId} />;
    }

    return hasIcon && <Icon styles={{ root: { fontSize: '16px', color: color } }} iconName={iconName}></Icon>;
  }, [hasIcon, item?.taskQueueId, errorPathName, status]);

  return (
    <div className="status-container">
      {renderIcon}
      <div className="status-content">
        {`${renderStatus} `}
        {hasIcon && itemFailed && (
          <>
            |
            <p className="error-view-detail" onClick={viewErrorDetail}>
              {translate('CaptionResource.ViewDetails')}
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default StatusHistory;
