export class ValidatedError {
  rowNo: number;
  reasons: string[];
  isValid: boolean;
  constructor(_rowNo: number, _reason: string[], _isValid: boolean) {
    this.rowNo = _rowNo;
    this.reasons = _reason;
    this.isValid = _isValid;
  }
}
