import { Icon } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RouteConstants } from '../../../core/constants/routes.constants';
import { hiddenModal } from '../../../services/modal.service';
import './index.scss';

const UploadFileSuccessModal = () => {
  const [translate] = useTranslation();
  const navigate = useNavigate();

  const onNavigateUploadHistory = () => {
    hiddenModal();
    navigate(RouteConstants.UploadHistory);
  };

  return (
    <div className="uploaded-processing-container">
      <Icon className="icon" iconName="HourGlass" />
      <div className="title">{translate('CommonResource.ValidateUploadedFile.UploadedAndPendingStatus')}</div>
      <div className="content">{translate('CommonResource.ValidateUploadedFile.UploadedAndPendingContent')}</div>

      <div className="navigate-upload-history" onClick={onNavigateUploadHistory}>
        {translate('CommonResource.NavigateUploadHistory')}
      </div>
    </div>
  );
};

export default UploadFileSuccessModal;
