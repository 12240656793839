import {
  ContextualMenu,
  DetailsList,
  DetailsListLayoutMode,
  GroupedListV2_unstable as GroupedListV2,
  IColumn,
  IContextualMenuItem,
  IGroup,
  IGroupShowAllProps,
  Label,
  Link,
  ScrollablePane,
  SelectionMode,
} from '@fluentui/react';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { SortedColumn } from '../../models/sorted-column.model';
import {
  GroupInformationRef,
  IOnixGroupPagination,
  IOnixGroupPaginationItem,
  IOnixTable,
  IOnixTableImperative,
  IOnixTablePagination,
  IOnixTableRequest,
} from './IOnixTable';
import './index.scss';
import { FluentConstant } from '../../constants/fluent.constant';
import { useInfinityScroll } from '../../hooks/useInfinityScroll';
import { useTable } from '../../hooks/useTable';

const FirstPageNumber = 0;

export const OnixTable = forwardRef((props: IOnixTable, ref: React.Ref<IOnixTableImperative>) => {
  const { translate } = props;
  const [items, setItems] = useState<any[]>([]);

  const [groupItems, setGroupItems] = useState<IOnixGroupPaginationItem[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);

  const [menuTarget, setMenuTarget] = useState(null as HTMLElement | null);
  const [isShowRightClickMenu, setIsShowRightClickMenu] = useState(false);

  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)

  const groupInformationRef = useRef<GroupInformationRef>({
    groups: undefined,
    groupItems: [],
  });

  const {
    sortedColumn,
    setSortedColumn,
    groupColumnName,
    setGroupColumnName,
    contextualMenuProps,
    groups,
    setGroups,
    renderDetailsHeader,
    onRenderHeader,
    headerColumnClick,
  } = useTable({
    sortedColumn: props.sortableColumn ?? { columnName: '', isDescending: true },
    groupColumnKeys: props?.groupColumnKeys ?? [],
    groupColumnName: props?.groupColumnName,
    onDefaultFromViewClick: props?.onDefaultFromViewClick,
  });

  const [fetchMoreId, onScroll, setFetchMoreId] = useInfinityScroll();
  const [paginationInfo, setPaginationInfo] = useState<{ pageNumber: number; pageSize: number; totalItems: number; isLastPage: boolean }>({
    pageNumber: 0,
    totalItems: 0,
    pageSize: 0,
    isLastPage: false,
  });

  const scrollablePanelId = useMemo(() => {
    return `scrollable-pane-${uuid().substring(0, 13)}`;
  }, []);

  useImperativeHandle(ref, () => {
    const result: IOnixTableImperative = {
      scrollToTop,
      selectItems,
      getAllItems,
      getItem,
      addNewItems,
      updateItems,
      removeItems,
      getSort,
      setSort,
      getGroup,
      setGroup,
      resetItems,
      scrollToElement,
    };
    return result;
  });

  const setSort = (sortedColumn?: SortedColumn) => {
    setSortedColumn(sortedColumn ? sortedColumn : { columnName: '', isDescending: false });
  };

  const getSort = (): SortedColumn => {
    return sortedColumn;
  };

  const setGroup = (groupColumnName?: string) => {
    setGroupColumnName(groupColumnName ?? undefined);
  };

  const getGroup = () => {
    return groupColumnName;
  };

  const resetItems = () => {
    scrollToTop();
    setFetchMoreId(undefined);
    setItems([]);
    setGroupItems([]);
  };

  const selectItems = (keys: any[]) => {
    const currentMode = props.selection.mode;
    if (currentMode === SelectionMode.none || (currentMode === SelectionMode.single && keys.length > 1)) {
      return;
    }
    const selection = props.selection;
    selection.setAllSelected(false);

    keys.forEach((key) => {
      const currentItemIndex = selection.getItems().findIndex((m) => props.predicateToGetKey(m) === key);
      if (currentItemIndex > -1) {
        selection.setIndexSelected(currentItemIndex, true, true);
      }
    });
  };

  const scrollToTop = useCallback(() => {
    const scrollable = document.querySelector(`.${scrollablePanelId} ${FluentConstant.ScrollPaneContainer}`);
    if (scrollable) {
      scrollable.scrollTo({
        top: 0,
      });
    }
  }, [scrollablePanelId]);

  const scrollToElement = useCallback(
    (index: any) => {
      if (!defaultGroupedColumnName) {
        const scrollable = document.querySelector(`.${scrollablePanelId} ${FluentConstant.ScrollPaneContainer}`);
        if (scrollable) {
          const selector = `.${scrollablePanelId} ${FluentConstant.ScrollPaneContainer} .ms-List-page .ms-List-cell[data-list-index="${index}"]`;
          const element = document.querySelector(selector);
          if (element) {
            // true : the top of the element will be aligned to the top of the visible area of the scrollable ancestor
            // false : false - the bottom of the element will be aligned to the bottom of the visible area of the scrollable ancestor.
            element.scrollIntoView(false);
          }
        }
      }
    },
    [scrollablePanelId]
  );

  const getAllItems = useCallback(() => {
    return items.map((m) => Object.assign({}, m));
  }, [items]);

  const getItem = useCallback(
    (key: any) => {
      const itemByKey = items.find((m) => props.predicateToGetKey(m) === key);
      if (itemByKey) {
        return Object.assign({}, itemByKey);
      }
      return undefined;
    },
    [items]
  );

  const addNewItems = (items: any[]) => {
    if (items.length === 0) {
      return;
    }
    if (defaultGroupedColumnName) {
      groupAddNewItems(items);
      return;
    }

    setItems((prevState) => items.concat(prevState));
    if (paginationInfo.isLastPage) {
      setPaginationInfo({
        ...paginationInfo,
        totalItems: paginationInfo.totalItems + items.length,
      });
    }
  };

  const updateItems = (updatingItems: any[], isAutoSelect: boolean = false, isEditMultipleItems: boolean = false) => {
    if (updatingItems.length === 0) {
      return;
    }
    const keys = updatingItems.map((m) => props.predicateToGetKey(m));
    if (defaultGroupedColumnName) {
      let smallestGroupIndex = undefined;
      if (isEditMultipleItems) {
        smallestGroupIndex = groupItems.findIndex((groupItem) => {
          const currentItemKeys = groupItem.items.map((item) => props.predicateToGetKey(item));
          return currentItemKeys.some((m) => keys.includes(m));
        });
      }
      groupUpdateItems(updatingItems, smallestGroupIndex);
    } else {
      normalUpdateItems(updatingItems);
    }
    if (isAutoSelect) {
      setTimeout(() => {
        selectItems(keys);
      }, 200);
    }
  };

  const removeItems = (keys: any[]) => {
    if (keys.length > 0) {
      if (defaultGroupedColumnName) {
        groupRemoveItems(keys);
      } else {
        setItems((prevState) => {
          const newState = prevState.filter((m) => !keys.includes(props.predicateToGetKey(m)));
          return newState;
        });
      }

      setPaginationInfo({
        ...paginationInfo,
        totalItems: paginationInfo.totalItems - keys.length,
      });
      if (!props.disableUnselectAllOnSetItems) {
        props.selection.setAllSelected(false);
      }
    }
  };

  const groupAddNewItems = (items: any[]) => {
    if (!groupColumnName || items.length === 0) {
      return;
    }
    // IMPORTANT: In the system we have situation to update, add, remove at the same time. So we needed base on RefObject for grouping to easier calculate
    const newGroupItems: IOnixGroupPaginationItem[] = [...groupInformationRef.current.groupItems];
    const newGroups: IGroup[] = [...(groupInformationRef.current.groups ?? [])];

    items.forEach((item) => {
      if (props.onGetInfoToAddNewGroup) {
        // IMPORTANT: Delete rowNumber property, because related to loadedRowNumber to fetch more item inside group
        if (item?.rowNumber) {
          delete item.rowNumber;
        }
        const { groupName, groupValue } = props.onGetInfoToAddNewGroup(item, defaultGroupedColumnName ?? '');

        const groupOfItem = newGroupItems.find((m) => m.groupValue === groupValue);
        if (groupOfItem) {
          groupOfItem.items.unshift(item);

          // Open current group
          const openingGroup = newGroups.find((m) => m.key === groupOfItem.groupValue);
          if (openingGroup) {
            openingGroup.isCollapsed = false;
          } else {
            newGroups.unshift({
              key: groupValue,
              isCollapsed: false,
            } as IGroup);
            groupOfItem.hasMoreItems = true;
          }
        } else {
          // Add new group and open this
          newGroups.unshift({
            key: groupValue,
            isCollapsed: false,
          } as IGroup);

          newGroupItems.unshift({
            groupName: getGroupName(groupName, groupValue, groupOfItem),
            groupValue: groupValue,
            hasMoreItems: true,
            items: [item],
          } as IOnixGroupPaginationItem);
        }
      }
    });

    setGroups(newGroups);
    setGroupItems(newGroupItems);
    if (groupInformationRef.current) {
      groupInformationRef.current.groups = [...newGroups];
      groupInformationRef.current.groupItems = [...newGroupItems];
    }
  };

  const normalUpdateItems = (updatingItems: any[]) => {
    if (updatingItems.length > 0) {
      setItems((prevState) => {
        const newItems = [...prevState];

        updatingItems.forEach((updateItem) => {
          const foundIndex = newItems.findIndex((m) => props.predicateToGetKey(m) === props.predicateToGetKey(updateItem));
          if (foundIndex >= 0) {
            if (props.isUpdateAllProperties) {
              newItems[foundIndex] = Object.assign({}, updateItem);
            } else {
              const newItem = { ...newItems[foundIndex] };
              Object.keys(updateItem).forEach((key) => {
                if (newItem[key] !== undefined) {
                  newItem[key] = updateItem[key];
                }
              });
              newItems[foundIndex] = newItem;
            }
          }
        });

        return newItems;
      });
    }
  };

  const groupUpdateItems = (updatingItems: any[], newGroupIndex?: number) => {
    if (updatingItems.length === 0) {
      return;
    }
    // IMPORTANT: In the system we have situation to update, add, remove at the same time. So we needed base on RefObject for grouping to easier calculate
    const newGroupItems: IOnixGroupPaginationItem[] = [...groupInformationRef.current.groupItems];
    const newGroups: IGroup[] = [...(groupInformationRef.current.groups ?? [])];

    updatingItems.forEach((updatingItem) => {
      const oldItem = items.find((m) => props.predicateToGetKey(m) === props.predicateToGetKey(updatingItem));
      let newItem = Object.assign({}, oldItem);

      if (props.isUpdateAllProperties) {
        newItem = Object.assign({}, updatingItem);
      } else {
        Object.keys(updatingItem).forEach((key) => {
          if (newItem[key] !== undefined) {
            newItem[key] = updatingItem[key];
          }
        });
      }

      if (oldItem && newItem && props.onGetInfoToAddNewGroup) {
        const { groupValue: oldGroupValue } = props.onGetInfoToAddNewGroup(oldItem, defaultGroupedColumnName ?? '');
        const { groupValue: newGroupValue, groupName: newGroupName } = props.onGetInfoToAddNewGroup(
          newItem,
          defaultGroupedColumnName ?? ''
        );
        if (oldGroupValue !== newGroupValue) {
          const groupItemWithOldGroupValue = newGroupItems?.find((m) => m.groupValue === oldGroupValue);
          // Remove current item in the group
          if (groupItemWithOldGroupValue) {
            groupItemWithOldGroupValue.items = groupItemWithOldGroupValue.items.filter(
              (m) => props.predicateToGetKey(m) !== props.predicateToGetKey(updatingItem)
            );
          }

          // IMPORTANT: Delete rowNumber property, because related to loadedRowNumber to fetch more item inside group
          if (newItem?.rowNumber) {
            delete newItem.rowNumber;
          }

          const groupItemWithNewGroupValue = newGroupItems?.find((m) => m.groupValue === newGroupValue);
          if (groupItemWithNewGroupValue) {
            groupItemWithNewGroupValue.items.unshift(newItem);

            const existedGroup = newGroups.find((m) => m.key === groupItemWithNewGroupValue.groupValue);
            if (existedGroup) {
              existedGroup.isCollapsed = false;
            } else {
              newGroups.push({ key: newGroupValue, isCollapsed: false } as IGroup);
              groupItemWithNewGroupValue.hasMoreItems = true;
            }
          } else {
            // Create new group and locate to before current group
            let index = 0;
            if (newGroupIndex !== undefined) {
              index = newGroupIndex;
            } else {
              if (groupItemWithOldGroupValue) {
                const oldGroupIndex = groupItems.findIndex((m) => m.groupValue === oldGroupValue);
                if (oldGroupIndex > -1) {
                  index = oldGroupIndex;
                }
              }
            }

            const newGroupItem = {
              groupName: newGroupName,
              groupValue: newGroupValue,
              items: [newItem],
              hasMoreItems: true,
            } as IOnixGroupPaginationItem;
            newGroupItems.splice(index, 0, newGroupItem);
            newGroups.push({ key: newGroupValue, isCollapsed: false } as IGroup);
          }
        } else {
          // Just update item inside current group
          const currentGroup = newGroupItems.find((m) => m.groupValue === oldGroupValue);
          if (currentGroup) {
            const neededUpdateItemIndex = currentGroup.items.findIndex(
              (m) => props.predicateToGetKey(m) === props.predicateToGetKey(newItem)
            );
            if (neededUpdateItemIndex > -1) {
              currentGroup.items.splice(neededUpdateItemIndex, 1, newItem);
            }
          }
        }
      }
    });

    setGroups(newGroups);
    setGroupItems(newGroupItems);
    if (groupInformationRef.current) {
      groupInformationRef.current.groups = [...newGroups];
      groupInformationRef.current.groupItems = [...newGroupItems];
    }
  };

  const groupRemoveItems = (keys: any[]) => {
    const keySet = new Set(keys);
    // IMPORTANT: In the system we have situation to update, add, remove at the same time. So we needed base on RefObject for grouping to easier calculate
    const newGroupItems: IOnixGroupPaginationItem[] = [...groupInformationRef.current.groupItems];
    newGroupItems.forEach((groupItem) => {
      groupItem.items = groupItem.items.filter((m) => !keySet.has(props.predicateToGetKey(m)));
    });

    setGroupItems(newGroupItems);
    if (groupInformationRef.current) {
      groupInformationRef.current.groupItems = [...newGroupItems];
    }
  };

  const columns = useMemo(() => {
    let columns: IColumn[] = [];
    if (props.columns) {
      columns = props.columns.map((m) => {
        let maxWidth = m.maxWidth ?? 60;
        const isSorted = sortedColumn?.columnName === m.key;
        const isGrouped = groupColumnName && groupColumnName === m.key;
        if (m.isIconOnly) {
          if (isSorted) {
            maxWidth += 20;
          }
          if (isGrouped) {
            maxWidth += 20;
          }
          m.headerClassName = 'width' + maxWidth;
          m.className = 'width' + maxWidth;
        }
        return {
          ...m,
          minWidth: m.minWidth ?? 40,
          maxWidth,
          isSorted,
          isSortedDescending: isSorted && sortedColumn.isDescending,
          isGrouped,
          onColumnClick: !props.disableColumnHeaderMenu ? headerColumnClick : undefined,
          onRenderHeader: m.onRenderHeader ?? onRenderHeader,
        } as IColumn;
      });
    }
    return [...columns.map((m) => Object.assign({}, m))];
  }, [props.columns, props.disableColumnHeaderMenu, sortedColumn, groupColumnName]);

  const getGroupName = useCallback(
    (groupName: string, groupValue: any, groupedColumn?: IColumn): string => {
      if (!groupedColumn) {
        return groupName;
      }

      const groupedByColumn = `${(groupedColumn.name || '').replace('.', '')}`;

      let finalGroupName = groupName;
      if (groupName?.toString().trim() === '') {
        finalGroupName = `${translate('CaptionResource.Caption44')}`;
      }
      if (props.onRenderGroupingText) {
        const valueByRenderGroupingText = props.onRenderGroupingText(groupValue, groupedColumn);
        if (valueByRenderGroupingText) {
          finalGroupName = valueByRenderGroupingText;
        }
      }
      return `${groupedByColumn}: ${finalGroupName}`;
    },
    [props.onRenderGroupingText]
  );

  const defaultGroupedColumn = useMemo(() => {
    let groupedColumn = columns.find((m) => m.key === groupColumnName);
    if (sortedColumn.columnName === '' && !groupColumnName && props.isGroupingDefaultMode) {
      groupedColumn = columns.find((m) => m.key === props.groupingKeyDefault);
    }
    return groupedColumn;
  }, [sortedColumn, groupColumnName, props.isGroupingDefaultMode, props.groupingKeyDefault]);

  const defaultGroupedColumnName = useMemo(() => {
    let groupColumnNameValue = groupColumnName;
    if (sortedColumn.columnName === '' && !groupColumnName && props.isGroupingDefaultMode) {
      groupColumnNameValue = props.groupingKeyDefault || '';
    }
    return groupColumnNameValue;
  }, [sortedColumn, groupColumnName, props.isGroupingDefaultMode, props.groupingKeyDefault]);

  useEffect(() => {
    if (groupInformationRef.current) {
      groupInformationRef.current.groups = groups ? [...groups] : undefined;
      groupInformationRef.current.groupItems = [...groupItems];
    }
  }, [groups, groupItems]);

  useEffect(() => {
    const newGroups: IGroup[] = [];
    const newItems: any[] = [];

    let startIndex = 0;
    groupItems.forEach((group) => {
      if (group.items.length > 0 || group.hasMoreItems) {
        const previousGroup = groups?.find((m) => m.key === group.groupValue);

        const groupName = getGroupName(group.groupName, group.groupValue, defaultGroupedColumn);

        const groupMapping = {
          key: group.groupValue,
          name: groupName,
          level: 0,
          startIndex: startIndex,
          count: group.items.length,
          hasMoreData: group.hasMoreItems,
          data: group,
          isCollapsed: previousGroup?.isCollapsed ?? true,
        } as IGroup;
        newGroups.push(groupMapping);
        newItems.push(...group.items);
        startIndex += group.items.length;
      }
    });

    if (newGroups.length > 0) {
      setGroups(newGroups);
      setItems(newItems);
    } else {
      setGroups(undefined);
      if (defaultGroupedColumn) {
        setItems([]);
      }
    }
  }, [groupItems]);

  useEffect(() => {
    if (fetchMoreId !== undefined && !paginationInfo.isLastPage && !isLoading) {
      setLoading(true);
      if (props?.onGetItems) {
        const pageNumber = paginationInfo.pageNumber + 1;
        const request = {
          sortedColumn,
          pageNumber: pageNumber,
          groupPageNumber: pageNumber,
          groupColumnName: groupColumnName,
        } as IOnixTableRequest;

        props
          .onGetItems(request)
          .then((res) => {
            if (res) {
              if (defaultGroupedColumnName) {
                const onixGroupPagination = Object.assign({}, res) as IOnixGroupPagination;
                setGroupItems((prevState) => {
                  const loadedGroupValues = prevState.map((m) => m.groupValue);
                  const newState = [...prevState];

                  const addGroups = onixGroupPagination.groups.filter((m) => !loadedGroupValues.includes(m.groupValue));
                  const mergeGroups = onixGroupPagination.groups.filter((m) => loadedGroupValues.includes(m.groupValue));

                  mergeGroups.forEach((mergeGroup) => {
                    const currentGroup = newState.find((m) => m.groupValue === mergeGroup.groupValue);
                    if (currentGroup) {
                      const loadedGroupKeys = currentGroup.items.map((m) => props.predicateToGetKey(m));
                      const addItems = mergeGroup.items.filter((m) => !loadedGroupKeys.includes(props.predicateToGetKey(m)));
                      if (addItems.length > 0) {
                        currentGroup.items = currentGroup.items.concat(addItems);
                      }
                      currentGroup.hasMoreItems = mergeGroup.hasMoreItems;
                      currentGroup.groupNumber = mergeGroup.groupNumber;
                    }
                  });

                  return prevState.concat(addGroups);
                });
                setPaginationInfo({
                  ...onixGroupPagination,
                  isLastPage: !onixGroupPagination.hasMoreGroups,
                });
              } else {
                const onixTablePagination = Object.assign({}, res) as IOnixTablePagination;
                setPaginationInfo({ ...onixTablePagination });
                if (onixTablePagination.items) {
                  setItems((prevState) => prevState.concat(onixTablePagination.items));
                }
              }
            }
          })
          .finally(() => setLoading(false));
      }
    }
  }, [fetchMoreId]);

  useEffect(() => {
    if (props.onSortableColumnChanges) {
      props.onSortableColumnChanges(sortedColumn);
    }
    if (props.onGetItems) {
      if (!props.disableUnselectAllOnSetItems) {
        props.selection.setAllSelected(false);
      }
      const request = {
        sortedColumn,
        pageNumber: FirstPageNumber,
        groupPageNumber: FirstPageNumber,
        groupColumnName: groupColumnName,
      } as IOnixTableRequest;
      setLoading(true);
      scrollToTop();
      props
        .onGetItems(request)
        .then((res) => {
          if (res) {
            setGroups([]);
            if (defaultGroupedColumnName) {
              const onixGroupPagination = Object.assign({}, res) as IOnixGroupPagination;
              setGroupItems(onixGroupPagination.groups);
              setPaginationInfo({
                isLastPage: !onixGroupPagination.hasMoreGroups,
                pageNumber: onixGroupPagination.pageNumber,
                pageSize: onixGroupPagination.pageSize,
                totalItems: onixGroupPagination.totalItems,
              });
            } else {
              setGroupItems([]);

              const onixTablePagination = Object.assign({}, res) as IOnixTablePagination;
              setPaginationInfo({
                ...onixTablePagination,
              });
              setItems(onixTablePagination.items || []);
            }
          }
        })
        .finally(() => {
          setIsFirstLoad(false);
          setLoading(false);
        });
    }
  }, [sortedColumn, groupColumnName, props.dependencyArrayToGetItems, props.disableUnselectAllOnSetItems]);

  const totalItems = useMemo((): string => {
    let totalItems = '';
    if (defaultGroupedColumnName) {
      let groupCounter = 0;
      groupItems.forEach((groupItem) => (groupCounter += groupItem.items.length));

      const isLoadedAllGroups = paginationInfo.isLastPage && groupItems.every((m) => !m.hasMoreItems);
      totalItems = `${groupCounter}${isLoadedAllGroups ? '' : '+'}`;
    } else {
      totalItems = `${items.length}+`;
      if (paginationInfo.isLastPage) {
        totalItems = `${paginationInfo.totalItems}`;
      }
    }
    return `${totalItems} `;
  }, [groupItems, items, paginationInfo, defaultGroupedColumnName]);

  const groupFetchMoreItems = (group?: IGroup) => {
    if (props?.onGetItems && group) {
      const currentItems = (group.data?.items as any[]) ?? [];

      // IMPORTANT: Loaded row number ignore all items add or update recently (Not fetch from API, don't know row number)
      const loadedRowNumber = currentItems.filter((m: any) => m?.rowNumber !== undefined).length;

      const request = {
        isFetchInsideGroup: true,
        sortedColumn,
        groupColumnName: groupColumnName,
        groupValue: group?.data?.groupValue,
        loadedRowNumber,
      } as IOnixTableRequest;
      props.onGetItems(request).then((res) => {
        if (res) {
          const firstGroup = (res as IOnixGroupPagination).groups[0];
          if (firstGroup) {
            const loadedKeys = currentItems.map((m) => props.predicateToGetKey(m));
            const addItems = firstGroup.items.filter((m) => !loadedKeys.includes(props.predicateToGetKey(m)));
            setGroupItems((prevState) => {
              const groupChanges = prevState.find((m) => m.groupValue === firstGroup.groupValue);
              if (groupChanges) {
                if (addItems.length > 0) {
                  groupChanges.items = groupChanges.items.concat(addItems);
                }
                groupChanges.hasMoreItems = firstGroup.hasMoreItems;
              }
              return [...prevState];
            });
            return;
          }
          // Exception case for cannot load groups
          setGroupItems((prevState) => {
            const groupChanges = prevState.find((m) => m.groupValue === group?.data?.groupValue);
            if (groupChanges) {
              groupChanges.hasMoreItems = false;
            }
            return [...prevState];
          });
        }
      });
    }
  };

  const onRenderShowAll = (groupShowAllProps?: IGroupShowAllProps) => {
    if (!groupShowAllProps?.group?.isCollapsed && groupShowAllProps?.group?.hasMoreData) {
      return (
        <Link className="load-more-button-group" underline onClick={() => groupFetchMoreItems(groupShowAllProps.group)}>
          {translate('Equipment.LoadMore')}
        </Link>
      );
    }
    return <></>;
  };

  const onItemContextMenu = useCallback(
    (ev?: Event) => {
      if (props.isShowRightClickMenu) {
        setIsShowRightClickMenu(true);
        setMenuTarget(ev?.target as any);
        ev?.stopPropagation();
      }
    },
    [props.isShowRightClickMenu]
  );

  const rightClickMenu = useMemo(() => {
    const handleCopyClipboard = () => {
      // we support copy for group row or grid cell so need to get cellTarget is role 'gridcell'
      let cellTarget = menuTarget;
      if (cellTarget?.getAttribute('role') != 'gridcell') {
        cellTarget = cellTarget?.closest('[role="gridcell"], [role="row"]') ?? null;
      }
      if (cellTarget?.classList.contains('ms-GroupHeader')) {
        cellTarget = cellTarget?.querySelector('[role="gridcell"].ms-GroupHeader-title') ?? null;
      }
      if (cellTarget?.classList.contains('ms-GroupHeader-title')) {
        cellTarget = cellTarget && cellTarget.children ? cellTarget && (cellTarget.children[0] as HTMLElement) : null;
      }

      //find column in current row target
      const column = columns?.find((x) => x.key == cellTarget?.getAttribute('data-automation-key'));
      // default: is inert text of grid cell
      // if need custom tooltip, it will take data from data-tooltip
      let content = cellTarget?.innerText;
      if (column?.isIconOnly) {
        content = cellTarget?.querySelector('[data-tooltip]')?.innerHTML ?? '';
      }
      navigator.clipboard.writeText(content ?? '');
    };
    return [
      {
        key: 'CopyToClipboard',
        text: translate('CommonResource.CopyToClipboard'),
        onClick: handleCopyClipboard,
      },
    ] as IContextualMenuItem[];
  }, [menuTarget, columns]);

  return (
    <div className="onix-table-container">
      <div className="onix-table-wrapper">
        {contextualMenuProps && <ContextualMenu {...contextualMenuProps} />}

        <ScrollablePane onScroll={onScroll} className={scrollablePanelId}>
          <DetailsList
            {...props}
            useReducedRowRenderer
            setKey="set"
            columns={columns}
            items={items}
            groups={groups}
            groupProps={{
              showEmptyGroups: true,
              onRenderShowAll: onRenderShowAll,
              isAllGroupsCollapsed: groups?.some((m) => m.isCollapsed),
              groupedListAs: props.isGroupedListV2 ? GroupedListV2 : undefined,
            }}
            // getGroupHeight={(group: IGroup) => handleGetGroupHeight(group)}
            className={`onix-table table-list ${props.className ?? ''}`}
            layoutMode={DetailsListLayoutMode.fixedColumns}
            onRenderDetailsHeader={renderDetailsHeader}
            selectionMode={props.selection?.mode}
            onItemContextMenu={props.onItemContextMenu ? props.onItemContextMenu : (_?: any, __?: any, ev?: Event) => onItemContextMenu(ev)}
          />
        </ScrollablePane>

        {items.length > 0 && isShowRightClickMenu && props.isShowRightClickMenu && (
          <ContextualMenu
            items={rightClickMenu}
            target={menuTarget}
            onDismiss={() => {
              setIsShowRightClickMenu(false);
            }}
          />
        )}

        {items.length === 0 && !isFirstLoad  && (
          <div className="empty-table">
            {props.onRenderEmpty ? props.onRenderEmpty() : <Label>{translate(`CommonResource.EmptyMessageHits`)}</Label>}
          </div>
        )}
      </div>

      {!props.hideTotalItems && (
        <div className="total-items">
          {translate('CaptionResource.Total')}:
          <span>
            {totalItems}
            {translate('CommonResource.lblItem')}
          </span>
          {props.onRenderAdditionalTotalItem &&
            props.onRenderAdditionalTotalItem(
              items,
              groupColumnName ? groupItems.every((m) => !m.hasMoreItems) && paginationInfo.isLastPage : paginationInfo.isLastPage
            )}
        </div>
      )}
    </div>
  );
});
