import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import { CommonGlobal } from './CommonGlobal';
import AuthenticatedLayout from './components/AuthenticatedLayout/AuthenticatedLayout';
import LoginLayout from './components/LoginLayout/LoginLayout';
import MainLayout from './components/MainLayout/MainLayout';
import NoAccessLayout from './components/NoAccessLayout/NoAccessLayout';
import { RouteConstants } from './core/constants/routes.constants';
import AuthenticationCallBack from './pages/Authentication/AuthenticationCallBack';
import Login from './pages/Authentication/Login';
import Logout from './pages/Authentication/Logout';
import { Denied } from './pages/Denied/Denied';
import { Home } from './pages/Home/Home';
import UploadHistory from './pages/UploadHistory/UploadHistory';
import PreviewFile from './pages/PreviewFile/PreviewFile';

function App() {
  return (
    <BrowserRouter>
      <CommonGlobal></CommonGlobal>
      <Routes>
        <Route path="/" element={<LoginLayout />}>
          <Route path="" element={<Navigate to={RouteConstants.Login} replace />}></Route>
          <Route path={RouteConstants.Login} element={<Login />}></Route>
          <Route path={RouteConstants.AuthCallBack} element={<AuthenticationCallBack />}></Route>
          <Route path={RouteConstants.Logout} element={<Logout />} />
        </Route>

        <Route path="" element={<AuthenticatedLayout />}>
          <Route path="" element={<MainLayout />}>
            <Route index element={<Home />}></Route>
            <Route path={RouteConstants.Home} element={<Home />}></Route>
            <Route path={RouteConstants.UploadHistory} element={<UploadHistory />}></Route>
          </Route>

          <Route path="" element={<NoAccessLayout />}>
            <Route path={RouteConstants.NoAccess} element={<Denied />}></Route>
          </Route>
        </Route>

        <Route path={RouteConstants.PreviewFile} element={<PreviewFile/>} ></Route>

        <Route path={RouteConstants.Others} element={<Navigate to={RouteConstants.Home} />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
