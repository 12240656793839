import { UserPrivileges } from '@onix/common';
import jwt_decode from 'jwt-decode';
import { UserManager } from 'oidc-client';
import ApplicationSetting from '../core/constants/application.constants';
import { Constants } from '../core/constants/constants';
import { SettingKeyConstants } from '../core/constants/setting-key.constants';
import { AppActions } from '../states/app/appSlice';
import { appDispatch } from '../states/store';
import { generateLocalKeyByName, getCurrentLanguage } from './common.service';
import { AuthenTypes } from '../core/constants/enums/authen.enum';
import { AuthenticationOidcService } from './authentication-oidc.service';

export interface IAuthenBase {
  login: (param: any) => void;
}

export const authentication: Record<AuthenTypes, any> = {
  [AuthenTypes.OIDC]: new AuthenticationOidcService(),
};

// Oidc.Log.logger = console;
// Oidc.Log.level = Oidc.Log.DEBUG;
const userManager = new UserManager(ApplicationSetting.oidcSetting);
userManager.events.addUserSignedOut(async () => {
  await logout(getCurrentLanguage());
});
userManager.events.addAccessTokenExpiring(async () => {
  userManager.signinSilent().catch((err) => console.error('refresh error', err));
});
const dispatch = appDispatch;

export const hasToken = () => {
  const token = getToken();
  if (token === null || token === '') {
    return false;
  }
  return true;
};

export const getToken = () => {
  return (
    sessionStorage.getItem(generateLocalKeyByName(SettingKeyConstants.AccessToken)) ||
    localStorage.getItem(generateLocalKeyByName(SettingKeyConstants.AccessToken)) ||
    ''
  );
};

export const getUser = () => {
  return userManager.getUser();
};

export const login = () => {
  dispatch(AppActions.showLoading());
  clearToken();
  // const isOnline = await this.ping();
  const isOnline = true;
  const promise = new Promise<boolean>(async (resolve, reject) => {
    if (isOnline) {
      return await userManager.signinRedirect();
    } else {
      try {
        // this.messageService.showOfflineMessage();
        resolve(false);
      } catch (err: unknown) {
        if (err instanceof Error) {
          if (err.message === 'OfflineUserNotExisted') {
            // this.messageService.showOfflineMessage();
            resolve(false);
          } else {
            reject(new Error(err.message));
          }
        } else {
          throw err;
        }
      }
    }
  }).finally(() => {
    dispatch(AppActions.hiddenLoading());
  });
  return promise;
};

export const completeAuthentication = () => {
  dispatch(AppActions.showLoading());
  return userManager
    .signinRedirectCallback()
    .then(async (user) => {

      setToken(user.access_token, SettingKeyConstants.AccessToken);
      setToken(user.refresh_token || '', SettingKeyConstants.RefreshToken);
      const userInfo: any = jwt_decode(user.access_token);
      setToken(JSON.stringify(userInfo), SettingKeyConstants.UserInfo)
    })
    .catch(async () => {
      login();
    })
    .finally(() => {
      dispatch(AppActions.hiddenLoading());
    });
};

export const refreshToken = async () => {
  dispatch(AppActions.showLoading());
  const user = await userManager.signinSilent();
  setToken(user.access_token, SettingKeyConstants.AccessToken);
  setToken(user.refresh_token || '', SettingKeyConstants.RefreshToken);
  dispatch(AppActions.hiddenLoading());
};

export const logout = async (language: string) => {
  dispatch(AppActions.showLoading());
  await userManager.signoutRedirect({ extraQueryParams: { culture: language } });
  await userManager.clearStaleState();
  await userManager.removeUser();
  clearToken();
  dispatch(AppActions.hiddenLoading());
};

export const setToken = (token: string, type: string) => {
  const rememberMe = false;
  if (rememberMe) {
    localStorage.setItem(generateLocalKeyByName(type), token);
  } else {
    sessionStorage.setItem(generateLocalKeyByName(type), token);
  }
};

export const clearToken = () => {
  localStorage.removeItem(generateLocalKeyByName(SettingKeyConstants.AccessToken));
  localStorage.removeItem(generateLocalKeyByName(SettingKeyConstants.RefreshToken));
  localStorage.removeItem(generateLocalKeyByName(SettingKeyConstants.UserInfo));
  sessionStorage.removeItem(generateLocalKeyByName(SettingKeyConstants.AccessToken));
  sessionStorage.removeItem(generateLocalKeyByName(SettingKeyConstants.RefreshToken));
  sessionStorage.removeItem(generateLocalKeyByName(SettingKeyConstants.UserInfo));
};

export const hasPrivilege = (userPrivilege: UserPrivileges, allowReadOnly = false) => {
  const currentToken = getToken();
  const tokenInfo: any = jwt_decode(currentToken);
  const value = tokenInfo[Constants.privilegeClaimsPrefix + userPrivilege];
  if (value === undefined) {
    return false;
  }
  if (value === '1') {
    return true;
  }
  if (!allowReadOnly) {
    return false;
  }
  return value === '0';
};

export const getCurrentAuthenticationService = (authenTypesString: string) => {
  if (authenTypesString) {
    const authenTypes = parseInt(authenTypesString);
    if (isNaN(authenTypes) || !(authenTypes in AuthenTypes)) {
      return null;
    }
    return authentication[authenTypes as AuthenTypes];
  }
  return null;
};

export const getUserInfo = () => {
  return (
    sessionStorage.getItem(generateLocalKeyByName(SettingKeyConstants.UserInfo)) ||
    localStorage.getItem(generateLocalKeyByName(SettingKeyConstants.UserInfo)) ||
    ''
  );
};