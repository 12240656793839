export const removeWhiteSpace = (value: string | undefined): string => {
  if (value) {
    return value.trim().replace(/\s+/g, ' ');
  }
  return '';
};

export const isValidGlobalId = (globalId: string) => {
  // Short GUID
  const shortGuidRegex = /^[0-9A-Za-z_-]{22}$/;
  const resultShortGUID = shortGuidRegex.test(globalId);

  // Long GUID
  const guidPattern = /[a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12}/i;
  const resultUuid = guidPattern.test(globalId);

  // Hex number
  var hexRegex = /^[0-9a-fA-F]+$/;
  var resultHexNumber = hexRegex.test(globalId);
  const resultLength = globalId.length >= 5 && globalId.length <= 40;

  return resultShortGUID || resultUuid || (resultHexNumber && resultLength);
};

export const globalIdInvalidPrefix = (globalId: string) => {
  if (typeof globalId !== 'string') return false;

  const notInPrefix =
    globalId.match(/3075AFA11C00005.*/) ||
    globalId.match(/3075AFA11FD08FC.*/) ||
    globalId.match(/3075AFA11C00003.*/) ||
    globalId.match(/3075AFA11C00006.*/);

  return notInPrefix;
};
