import { IconButton } from '@fluentui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { RouteConstants } from '../../../core/constants/routes.constants';
import './index.scss';

export default function LeftMenu() {
  const [translate] = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [menuSelected, setMenuSelected] = useState('home');

  useEffect(() => {
    const pathName = location.pathname !== '/' ? location.pathname : `/${RouteConstants.Home}`;
    const rootPathNameSplitter = pathName.split('/')[1];
    setMenuSelected(rootPathNameSplitter);
  }, [location.pathname]);

  //region Resizing the menu
  const [isWide, setIsWide] = useState(window.innerWidth >= 768);

  // Update menu width when window is resized
  useEffect(() => {
    const handleResize = () => {
      setIsWide(window.innerWidth >= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleWidth = () => {
    setIsWide(!isWide);
  };
  //endregion

  const menuClicked = (url: string) => {
    navigate(url);
  };

  const menuItemGenerate = (url: string, iconName: string, displayName: string) => {
    return (
      <div className={'menu-item selection' + (url === menuSelected ? ' menu-item-selected' : '')} onClick={() => menuClicked(url)}>
        <IconButton allowDisabledFocus iconProps={{ iconName }} className="menu-item-icon"></IconButton>
        <span>{displayName}</span>
      </div>
    );
  };

  return (
    <div className={`home-left-menu ${isWide ? 'wide-menu' : 'narrow-menu'}`}>
      <div className="menu-item">
        <IconButton
          allowDisabledFocus
          iconProps={{ iconName: 'GlobalNavButton', iconType: 1 }}
          className="menu-item-icon collapse"
          onClick={toggleWidth}
        ></IconButton>
      </div>

      {menuItemGenerate(RouteConstants.Home, 'Contact', translate('Menu.Home'))}
      {menuItemGenerate(RouteConstants.UploadHistory, 'History', translate('AppInspect.UploadHistory'))}
    </div>
  );
}
