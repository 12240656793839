import { Icon } from '@fluentui/react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import OnixMakerLogoWhite from '../../../assets/images/onix-maker-text-white.svg';
import OnixMakerLogoSmall from '../../../assets/images/onix-maker-white.svg';
import { showModal } from '../../../services/modal.service';
import { ShowModalPayload } from '../../../states/modal/modalSlice';
import { userSelector } from '../../../states/user/userSlice';
import { AccountAction } from './AccountAction/AccountAction';
import CompanyDescription from './CompanyDescription/CompanyDescription';
import { Company } from './CompanyModal/Company';
import './index.scss';

export default function Header() {
  const user = useSelector(userSelector);
  const [translate] = useTranslation();

  const companyClassName = useMemo(() => {
    let companyClass = 'company-div ';
    if (!user.hasMultipleOrganization) {
      companyClass += 'company-default-cursor';
    }
    return companyClass;
  }, [user.hasMultipleOrganization]);

  const openChangeOrganizationModal = () => {
    const showModalPayload: ShowModalPayload = {
      component: <Company />,
      props: { isBlocking: true },
      style: { width: '820px', height: '670px' },
      confirmEvent: () => {},
      title: translate('CommonResource.SwitchOrg'),
      description: <CompanyDescription />,
      closeIcon: true,
    };
    user.hasMultipleOrganization && showModal(showModalPayload);
  };

  return (
    <div className="header">
      <div className="left-position">
        <div>
          <Link to="/home">
            <img alt="onix-maker-logo" src={OnixMakerLogoWhite} className="desktop"></img>
          </Link>
        </div>
        <div>
          <Link to="/home">
            <img alt="onix-maker-logo" src={OnixMakerLogoSmall} className="mobile"></img>
          </Link>
        </div>
        <div className="divider-right"></div>
        <div className={companyClassName} onClick={openChangeOrganizationModal}>
          <span className="company-span">{user.companyName}</span>
          {user.hasMultipleOrganization && <Icon iconName="ChevronDown" className="company-icon"></Icon>}
        </div>
      </div>

      <div className="right-position">
        <AccountAction></AccountAction>
      </div>
    </div>
  );
}
