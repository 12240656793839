import { DialogTypeEnum } from '../core/constants/enums/dialog-type.enum';
import { ConfirmDialog } from '../models/confirm-dialog.model';
import { AppActions, ConfirmDialogState } from '../states/app/appSlice';
import { appDispatch } from '../states/store';

const dispatch = appDispatch;

export const showErrorDialog = (messageCaption: string, message: string = '', type: DialogTypeEnum = DialogTypeEnum.Error, title = '') => {
  const payload = {
    type: type,
    message: message,
    messageCaption: messageCaption,
    title: title,
  };
  dispatch(AppActions.showErrorDialog(payload));
};

export const showErrorDialogWithResponse = (err: any, type: DialogTypeEnum = DialogTypeEnum.Error) => {
  const payload = {
    type: type,
    message: err?.message ?? '',
    messageCaption: err?.messageCaption ?? '',
    title: '',
  };
  dispatch(AppActions.showErrorDialog(payload));
};

export const hiddenErrorDialog = () => {
  dispatch(AppActions.hiddenErrorDialog());
};

export const showConfirmDialog = (confirmDialog: ConfirmDialog) => {
  const payload = {
    ...confirmDialog,
  } as ConfirmDialogState;

  dispatch(AppActions.showConfirmDialog(payload));
};

export const hiddenConfirmDialog = () => {
  dispatch(AppActions.hiddenConfirmDialog());
};

export const showCommonDialog = (title: string, message: string = '', maxWidth?: number) => {
  const payload = {
    title: title,
    message: message,
    maxWidth: maxWidth
  };
  appDispatch(AppActions.showCommonDialog(payload))
}

export const hiddenCommonDialog = () => {
  appDispatch(AppActions.hiddenCommonDialog());
}