import jwtDecode from 'jwt-decode';
import { UserManager } from 'oidc-client';
import { AppActions } from '../states/app/appSlice';
import { appDispatch } from '../states/store';
import { IAuthenBase, getToken, setToken } from './authentication.service';
import ApplicationSetting from '../core/constants/application.constants';
import { getLocalStorageItem, removeLocalStorageItem } from '../core/utils/localStorage.utils';
import { getSessionStorageItem } from '../core/utils/sessionStorage.utils';
import { SettingKeyConstants } from '../core/constants/setting-key.constants';

/**
 * Service class for handling authentication using OpenID Connect (OIDC).
 */
export class AuthenticationOidcService implements IAuthenBase {
  userManager = new UserManager(ApplicationSetting.oidcSetting);

  constructor() {
    // Subscribe to events raised when the user's sign-in status at the OP has changed
    this.userManager.events.addUserSignedOut(async () => {
      await this.logout({ isCancelCallLogout: true });
    });

    // Subscribe to events raised prior to the access token expiring
    this.userManager.events.addAccessTokenExpiring(async () => {
      const currentToken = getToken();
      if (currentToken) {
        const { companyId } = jwtDecode(currentToken) as { companyId: number };
        await this.refresh({ targetExpressCompanyId: companyId });
      }
    });
  }

  /**
   * Initiates the OIDC login process.
   * @param {Object} params - Additional parameters for the login process.
   */
  public login = (params: any) => {
    this.userManager.signinRedirect({
      extraQueryParams: {
        friendlyAlias: params?.inputAlias,
      },
    });
  };

  /**
   * Initiates the OIDC logout process.
   * @param {Object} params - Additional parameters for the logout process.
   */
  public logout = async (params: any) => {
    try {
      appDispatch(AppActions.showLoading());
      var authenticatedUser = await this.userManager.getUser();
      if (authenticatedUser != null) {
        await this.userManager
          .signoutRedirect({
            extraQueryParams: {
              culture: getLocalStorageItem(SettingKeyConstants.Language) || 'en',
              friendlyAlias: getSessionStorageItem(SettingKeyConstants.InputAlias),
              isCancelCallLogout: params?.isCancelCallLogout ?? false,
            },
          })
          .then(async () => {
            await this.userManager.clearStaleState();
            await this.userManager.removeUser();
            this.clearLocalStorage();
          });
      }
    } catch (error) {
    } finally {
      appDispatch(AppActions.hiddenLoading());
    }
  };

  /**
   * Switches the current user's associated company within the OIDC authentication context.
   * @param {number} targetCompanyId - The ID of the target company to switch to.
   */
  public switchingOrganization = async (targetCompanyId: number) => {
    return await this.refresh({ targetCompanyId: targetCompanyId });
  };

  /**
   * Initiates the refresh token process to obtain a new access token using a refresh token.
   * @param {number} targetCompanyId - The ID of the target company to switch to (optional).
   * @returns {Object} An object indicating the success of the refresh token process.
   */
  public refresh = async (params: any) => {
    try {
      appDispatch(AppActions.showLoading());
      const tokenResult = await this.userManager.signinSilent({
        request_company_id: params?.targetCompanyId ?? '',
      });
      if (tokenResult) {
        setToken(tokenResult.access_token, SettingKeyConstants.AccessToken);
        setToken(tokenResult.refresh_token || '', SettingKeyConstants.RefreshToken);
        return { isSuccess: true };
      }
    } catch (error) {
      return { isSuccess: false };
    } finally {
      appDispatch(AppActions.hiddenLoading());
    }
  };

   /**
   * Clears specific items from the local storage.
   */
  public clearLocalStorage = () => {
    const keys = [
      SettingKeyConstants.AccessToken,
      SettingKeyConstants.RefreshToken,
      SettingKeyConstants.AuthenticatedUserInfo,
      SettingKeyConstants.CompanyId,
    ];
    keys.forEach((key) => removeLocalStorageItem(key));
  };
}