import { WebStorageStateStore } from 'oidc-client';
import { ApplicationSettingInterface } from '../models/application-setting.model';

export const environment: ApplicationSettingInterface = {
  name: 'Test',
  clientApplicationId: 2,
  apiUrl: 'https://onix-maker-api-test.azurewebsites.net',
  identityApiUrl: 'https://login-test.onix.com',
  enableBanner: true,
  myAccountUrl: 'https://myaccount-test.onix.com/',
  applicationInsightKey: '1fba0b21-bb90-44aa-9e70-fe85359c891f',
  applicationInsightBufferKey: 'applicationInsightBuffer',
  oidcSetting: {
    authority: 'https://login-test.onix.com',
    client_id: 'F9U5hu4vrDR8gyhF54diJ2tsJZ8p5G0Tte',
    redirect_uri: 'https://maker-test.onix.com/auth-callback',
    post_logout_redirect_uri: 'https://maker-test.onix.com/login',
    response_type: 'code',
    scope: 'openid email onixmaker offline_access',
    filterProtocolClaims: false,
    loadUserInfo: false,
    automaticSilentRenew: false,
    //checkSessionInterval: 2000,
    userStore: new WebStorageStateStore({ store: window.sessionStorage }),
    stateStore: new WebStorageStateStore({ store: window.sessionStorage }),
    monitorSession: true,
    includeIdTokenInSilentRenew: false,
    revokeAccessTokenOnSignout: true,
  },
};
