import { CustomSearchBox, DateTimeHelper, Language, LanguageEnum, LanguageHelper, OnixTable } from '@onix/common';
import './index.scss';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CheckboxVisibility, ColumnActionsMode, IColumn, Icon, Selection, SelectionMode } from '@fluentui/react';
import { TableColumnConstant } from '../../core/constants/table-column.constant';
import { onRenderCell } from '../../hooks/useTable';
import { IOnixTablePagination, IOnixTableRequest } from '@onix/common/src/components/OnixTable/IOnixTable';
import { UploadFile } from '../../models/upload-history.model';
import StatusHistory from './StatusHistory/StatusHistory';
import FileDetailPanel from './FileDetailPanel/FileDetailPanel';
import { getUploadHistory } from '../../services/upload-history.service';
import { getNameFromPath } from '../../core/utils/fileHelper';
import { useTracking } from '../../hooks/useTracking';
import { TrackingEvent } from '../../core/constants/tracking-event.constant';
import { FilePreview } from '../../models/file-preview.model';
import { useSelector } from 'react-redux';
import { userSelector } from '../../states/user/userSlice';
import { NorwegianLanguage, SupportedLanguageConstants } from '@onix/common/src/constants/language.constants';

const Column = {
  ImportedFile: 'pathFileName',
  UploadedDate: 'uploadedDate',
  UploadedBy: 'uploadedBy',
  NumOfEquipment: 'numberOfEquipment',
  Status: 'status',
};

const UploadHistory = () => {
  const [translate] = useTranslation();
  const ref = useRef(null);
  const [searchText, setSearchText] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [col, setCol] = useState<IColumn[]>([]);
  const [filePreview, setFilePreview] = useState<FilePreview>({
    id: 0,
    isError: false,
  });
  const [localeConfig, setLocaleConfig] = useState<Language>(NorwegianLanguage);

  const user = useSelector(userSelector);

  const { trackPageView } = useTracking();

  const dependencyArrayToGetItems = useMemo(() => {
    return [searchText];
  }, [searchText]);

  useEffect(() => {
    const currentLanguage = LanguageHelper.getLanguageCode(user.languageId as LanguageEnum);
    const localeConfig = SupportedLanguageConstants.find((x) => x.code === currentLanguage) ?? NorwegianLanguage;
    setLocaleConfig(localeConfig);
  }, [user.languageId]);

  useEffect(() => {
    trackPageView(TrackingEvent.UploadHistoryPage);
  }, []);

  useEffect(() => {
    // let statusColSize = TableColumnConstant.LargeColumnDimension;
    // if (ref !== null && ref.current !== null) {
    //   const div = ref.current as HTMLDivElement;
    //   const padding = 116;
    //   statusColSize = div.offsetWidth - (TableColumnConstant.StandardColumnDimension + 20) * 4 - padding;
    // }

    const createColumn = (
      key: string,
      name: string,
      maxWidth: number = TableColumnConstant.StandardColumnDimension,
      onRender: (item?: UploadFile, column?: IColumn) => JSX.Element | null = onRenderCell
    ): IColumn => {
      return {
        key,
        name,
        fieldName: key,
        minWidth: 40,
        maxWidth,
        isResizable: true,
        columnActionsMode: ColumnActionsMode.disabled,
        onRender: (item, _, column) => onRender(item, column),
      };
    };

    setCol([
      createColumn(
        Column.ImportedFile,
        translate('CommonResource.ImportedFile'),
        TableColumnConstant.StandardColumnDimension,
        (item, column) =>
          onRenderCell(
            item,
            column,
            renderImportedFile(item?.taskQueueId, item?.pathFileName, true),
            renderImportedFile(item?.taskQueueId, item?.pathFileName)
          )
      ),
      createColumn(
        Column.UploadedDate,
        translate('CommonResource.UploadedDate'),
        TableColumnConstant.StandardColumnDimension,
        (item, column) => {
          const dateFormat = localeConfig.formatDateTime.dateTime;
          const momentFormat = dateFormat.replaceAll('d', 'D');
          const date = item ? DateTimeHelper.formatDateTime(item.uploadedDate, momentFormat) : '';
          return onRenderCell(item, column, <div>{date}</div>);
        }
      ),
      createColumn(Column.UploadedBy, translate('CommonResource.UploadedBy')),
      createColumn(
        Column.NumOfEquipment,
        translate('CommonResource.NumbOfEquipment'),
        TableColumnConstant.StandardColumnDimension,
        (item, column) => {
          const formattedNumber = item ? item.numberOfEquipment.toLocaleString(localeConfig.formatNumber) : 0;
          return onRenderCell(item, column, <div>{formattedNumber}</div>);
        }
      ),
      createColumn(Column.Status, translate('CommonResource.lblStatus'), TableColumnConstant.ExtraLargeColumnDimension, (item, column) =>
        onRenderCell(
          item,
          column,
          <StatusHistory item={item} hasIcon={true} viewDetail={openFileDetail} />,
          <StatusHistory item={item} hasIcon={false} />
        )
      ),
    ]);
  }, [translate, ref.current, user.languageId]);

  const getUploadHistories = async (request: IOnixTableRequest) => {
    const payload = {
      textSearch: searchText,
      pageIndex: request.pageNumber !== undefined && request.pageNumber > 0 ? request.pageNumber : 1,
      pageSize: 100,
    };

    const uploadHistories = await getUploadHistory(payload);
    return {
      isLastPage: uploadHistories.isLastPage,
      items: uploadHistories.items,
      pageNumber: uploadHistories.pageIndex,
      pageSize: 100,
      totalItems: uploadHistories.totalItems,
    } as IOnixTablePagination;
  };

  const [selection] = useState(
    new Selection({
      onSelectionChanged: () => {},
      selectionMode: SelectionMode.single,
    })
  );

  const onDismissPanel = () => {
    setIsOpen(false);
  };

  const openFileDetail = (taskQueueId: number | undefined, isError?: boolean) => {
    setFilePreview({
      id: taskQueueId ?? 0,
      isError: isError ?? false,
    });
    setIsOpen(true);
  };

  const renderImportedFile = (taskQueueId: number | undefined, fileNamePath: string | undefined, hasIcon?: boolean) => {
    const fileName = fileNamePath !== undefined ? getNameFromPath(fileNamePath) : '';
    const taskId = taskQueueId ?? 0;
    return (
      <div className="imported-file-container">
        {fileName !== '' && hasIcon && (
          <Icon className="icon-imported-excel" styles={{ root: { fontSize: '16px' } }} iconName="ExcelDocument"></Icon>
        )}
        <div className="link-imported" onClick={() => openFileDetail(taskId)}>
          {fileName}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="upload-history">
        <div className="upload-search ">
          <CustomSearchBox
            classNames="search-box"
            placeholder={translate('CaptionResource.Caption38')}
            onSearch={(newValue) => setSearchText(newValue || '')}
            onClear={() => setSearchText('')}
          ></CustomSearchBox>
        </div>
        <div className="content-body" ref={ref}>
          <OnixTable
            compact
            columns={col}
            selection={selection}
            translate={translate}
            predicateToGetKey={(file: UploadFile) => file.taskQueueId}
            onGetItems={getUploadHistories}
            dependencyArrayToGetItems={dependencyArrayToGetItems}
            checkboxVisibility={CheckboxVisibility.always}
          ></OnixTable>
        </div>
      </div>
      {isOpen && <FileDetailPanel isOpen={isOpen} onDismiss={onDismissPanel} filePreview={filePreview}></FileDetailPanel>}
    </>
  );
};

export default UploadHistory;
