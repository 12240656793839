import { WebStorageStateStore } from 'oidc-client';
import { ApplicationSettingInterface } from '../models/application-setting.model';

export const environment: ApplicationSettingInterface = {
  name: 'Dev',
  clientApplicationId: 2,
  apiUrl: 'https://localhost/api-maker-local',
  identityApiUrl: 'https://localhost/identity-local',
  enableBanner: true,
  myAccountUrl: 'http://localhost:3000/',
  applicationInsightKey: 'cf5858f3-61ee-441f-a504-7fed78440c71',
  applicationInsightBufferKey: 'applicationInsightBuffer',
  oidcSetting: {
    authority: 'https://localhost/identity-local',
    client_id: 'F9U5hu4vrDR8gyhF54diJ2tsJZ8p5G0Tde',
    redirect_uri: 'http://localhost:3004/auth-callback',
    post_logout_redirect_uri: 'http://localhost:3004/login',
    response_type: 'code',
    scope: 'openid email onixmaker offline_access',
    filterProtocolClaims: false,
    loadUserInfo: false,
    automaticSilentRenew: false,
    //checkSessionInterval: 2000,
    userStore: new WebStorageStateStore({ store: window.sessionStorage }),
    stateStore: new WebStorageStateStore({ store: window.sessionStorage }),
    monitorSession: false, //only work normally via https, so turn off for development mode
    includeIdTokenInSilentRenew: false,
    revokeAccessTokenOnSignout: true,
  },
};
