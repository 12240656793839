import { Icon, PanelType } from '@fluentui/react';
import { PanelWithScrollableBody } from '@onix/common';
import { useTranslation } from 'react-i18next';
import './index.scss';
import { useEffect, useState } from 'react';
import { downloadFileDetail } from '../../../services/upload-history.service';
import { FileConstants } from '../../../common/constants/file.constant';
import { base64ToBlob, buildEncodeFileDetailUrl, downloadFileFromBlob } from '../../../core/utils/fileHelper';
import { Constants } from '../../../core/constants/constants';
import { RouteConstants } from '../../../core/constants/routes.constants';

const FileDetailPanel = (props: any) => {
  const [translate] = useTranslation();
  const { isOpen, onDismiss, filePreview } = props;
  const { id, isError } = filePreview;
  const [fileContent, setFileContent] = useState<string>('');

  useEffect(() => {
    const getFile = async () => {
      const file = `${FileConstants.MICROSOFT_DOCUMENT_VIEWER_URL}${buildEncodeFileDetailUrl(id, isError)}`;
      setFileContent(file);
    };

    getFile();
  }, []);

  const downloadFile = async () => {
    if (id === 0) return;

    const payload = {
      id: id,
      isError: isError,
    };

    const res = await downloadFileDetail(payload);
    if (res) {
      var blob = base64ToBlob(res.bytes, Constants.excelMediaType);
      var url = URL.createObjectURL(blob);
      downloadFileFromBlob(url, res.fileName);
    }
  };

  const openNewWindow = () => {
    const urlParameters = `${RouteConstants.PreviewFile}?id=${id}&isError=${isError}`;
    window.open(urlParameters, 'blank', 'location=yes,height=640,width=480,scrollbars=yes,status=yes');
  };

  const headerBtn = () => {
    return (
      <>
        <div className="file-detail-btn-action">
          <div className="btn-download" onClick={() => downloadFile()}>
            <Icon className="" iconName="Download" />
            <span>{translate('Home.Download')}</span>
          </div>

          <div className="btn-open" onClick={() => openNewWindow()}>
            <Icon iconName="OpenInNewWindow"></Icon>
            <span>{translate('CommonResource.Openlbl')}</span>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <PanelWithScrollableBody
        isOpen={isOpen}
        onDismiss={onDismiss}
        renderHeader={headerBtn}
        panelProps={{
          type: PanelType.smallFluid,
        }}
        renderBody={() => <iframe title="excelFileFrame" className="frame-excel-content" src={fileContent}></iframe>}
        renderFooter={() => <></>}
      />
    </>
  );
};

export default FileDetailPanel;
