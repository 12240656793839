export class Constants {
  public static privilegeClaimsPrefix = 'p_';
  public static excelMediaType = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  public static cancelUploadFile = 'File uploading has been canceled';

  public static ProductNoColumn = 'Product no.';
  public static SerialNoColumn = 'Serial no.';
  public static BatchNoColumn = 'Batch no.';
  public static OwnerEquipmentIdColumn = "Owner's equip. ID";
  public static ProdYearColumn = 'Prod. year';
  public static GlobalIdColumn = 'Global ID';
}
