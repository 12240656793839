import { Navigate, Outlet } from 'react-router-dom';
import { RouteConstants } from '../../core/constants/routes.constants';
import { hasToken } from '../../services/authentication.service';

export default function AuthenticatedLayout() {
  const hasTokenCurrently = hasToken();

  if (hasTokenCurrently) {
    return <Outlet></Outlet>;
  }

  return <Navigate to={RouteConstants.Login} replace></Navigate>;
}
