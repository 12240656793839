import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { ThemeProvider } from '@fluentui/react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import './assets/css/_fluent.scss';
import './assets/css/_global.scss';
import { defaultTheme } from './assets/themes/default';
import { ApiConstants } from './core/constants/api.constants';
import languageConfiguration from './core/utils/languageHelper';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { store } from './states/store';
import { reactPlugin } from './core/constants/application-insight.constant';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';

languageConfiguration(ApiConstants.OnixMakerApi.Setting.GetLanguages);
initializeIcons();
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <AppInsightsContext.Provider value={reactPlugin}>
      <ThemeProvider applyTo="body" theme={defaultTheme}>
        <App />
      </ThemeProvider>
    </AppInsightsContext.Provider>
  </Provider>
);

reportWebVitals();
