import { Navigate } from 'react-router-dom';
import { login, hasToken } from "../../services/authentication.service";

export default function Login() {
  const hasTokenCurrent = hasToken();
  if (!hasTokenCurrent) {
        login();
      return (
          <></>
      )
  }
  return <Navigate to="/home"></Navigate>;
}
